import { LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "./type";
import axios from "../config";
import swal from "sweetalert";

export const loginAction = (form) => {
  return (dispatch) => {
    dispatch(loginRequest());
    axios
      .post("login", form)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            dispatch(loginSuccess(response.data));
          } else {
            swal("Failed!", response.data.message, "error");
            dispatch(loginFailed(response.data.message));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          swal("Failed!", error.response.data.message, "error");
          dispatch(loginFailed(error.response.data.message));
        } else {
          swal("Failed!", error.message, "error");
          dispatch(loginFailed(error.message));
        }
      })
      .then(function () {
      });
  };
};

export const logout = () => {
  return { type: LOGOUT };
};

export const loginSuccess = (dataUser) => {
  return {
    type: LOGIN_SUCCESS,
    payload: dataUser,
  };
};

export const loginRequest = () => {
  return { type: LOGIN_REQUEST };
};
export const loginFailed = (error) => {
  return {
    type: LOGIN_FAILED,
    payload: error,
  };
};
