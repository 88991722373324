import React, { Component } from "react";
import Header from "../../layouts/Header";
import { Breadcrumb } from "../../layouts/Breadcrumb";
import { ModalDetail, ModalFilter } from "../../layouts/Modal";
import { connect } from "react-redux";
import { checkNewData, getOrder } from "../../store/order/actions";
import ReactPaginate from "react-paginate";
import Showing from "../../layouts/Showing";
import Search from "../../layouts/Search";
import { LoadData } from "../../layouts/Loading";
import swal from "sweetalert";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Done extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailData: [],
      sQuery: "",
      download: false,
      filter: false,
    };
    // setInterval(() => {
    //   this.props.checkNewData({
    //     token: this.props.token,
    //     url: "order?status=delivered",
    //   });
    // }, 20000);
  }
  showModal = (data) => {
    this.setState({
      detailData: data,
    });
  };
  componentDidMount() {
    this.props.getOrder({
      token: this.props.token,
      status: "delivered",
      search: this.state.sQuery,
    });
  }
  status = (status) => {
    switch (status) {
      case "delivered":
        status = status.toUpperCase();
        break;
      default:
        status = status.toUpperCase();
        break;
    }
    return <p className={status}>{status}</p>;
  };
  getOrderbyPage = (currentPage) => {
    if (this.state.filter.filter) {
      this.props.getOrder({
        token: this.props.token,
        page: currentPage.selected + 1,
        search: this.state.sQuery,
        status: "delivered",
        filter: this.state.filter.filter,
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate,
        courier: this.state.filter.courier,
      });
    } else {
      this.props.getOrder({
        token: this.props.token,
        page: currentPage.selected + 1,
        search: this.state.sQuery,
        status: "delivered",
      });
    }
  };
  getOrderbyLimit = (limit) => {
    if (this.state.filter.filter) {
      this.props.getOrder({
        token: this.props.token,
        limit: limit.target.value,
        status: "delivered",
        search: this.state.sQuery,
        filter: this.state.filter.filter,
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate,
        courier: this.state.filter.courier,
      });
    } else {
      this.props.getOrder({
        token: this.props.token,
        limit: limit.target.value,
        status: "delivered",
        search: this.state.sQuery,
      });
    }
  };
  useFilter = (event) => {
    if (event) {
      this.props.getOrder({
        token: this.props.token,
        status: "delivered",
        filter: event.filter,
        startDate: event.startDate,
        endDate: event.endDate,
        courier: event.courier,
      });
      this.setState({
        download: true,
        filter: event,
      });
    }
  };
  resetFilter = () => {
    this.setState({
      download: false,
      filter: false,
    });
    this.props.getOrder({
      token: this.props.token,
      status: "delivered",
      search: this.state.sQuery,
    });
  };

  render() {
    const state = this.state;
    if (this.props.error !== null && this.props.error !== undefined) {
      swal("Something Wrong!", `${this.props.error}`, "error").then((value) =>
        this.props.getOrder({
          token: this.props.token,
          status: "delivered",
          search: this.state.sQuery,
        })
      );
    }
    if (this.props.update) {
      this.props.getOrder({
        token: this.props.token,
        status: "delivered",
        search: this.state.sQuery,
      });
    }
    let data = [];
    for (let index = 0; index < this.props.incomingOrder.length; index++) {
      data.push([
        this.props.incomingOrder[index].do.awb,
        this.props.incomingOrder[index].order_number,
        this.props.incomingOrder[index].document_number,
        this.props.incomingOrder[index].items[0].imei,
        this.props.incomingOrder[index].items[0].sku,
        this.props.incomingOrder[index].items[0].msisdn,
        this.props.incomingOrder[index].items[0].qty,
        this.props.incomingOrder[index].receiver.name,
        this.props.incomingOrder[index].receiver.phone,
        this.props.incomingOrder[index].receiver.destination,
        this.props.incomingOrder[index].courier.name,
        this.props.incomingOrder[index].courier.driver,
        this.props.incomingOrder[index].courier.police_no,
      ]);
    }
    const multiDataSet = [
      {
        columns: [
          "No. Resi",
          "Order Number",
          "Document Number",
          "IMEI",
          "SKU",
          "MSISDN",
          "Quantity",
          "Penerima",
          "No. HP",
          "Alamat",
          "Courier Name",
          "Driver",
          "Police Number",
        ],
        data: data,
      },
    ];
    return (
      <>
        <Header title="Done" />
        <ModalDetail data={state.detailData} />
        <ModalFilter Filter={this.useFilter} reset={this.resetFilter} />
        <div className="base">
          <Breadcrumb subtitle="Done" />
          <div className="table-responsive base-table">
            <div className="row mb-4 top-content align-items-center">
              <div className="col-md-6 col-6">
                <div className="col-md-6 col-8">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-3">show</div>
                    <div className="col-md-5 col-5">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => this.getOrderbyLimit(e)}
                      >
                        <option defaultValue value="10">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-4">entires</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-md-6 col-6 align-self-end px-0">
                    <div className="row justify-content-end align-items-center">
                      <div className="col-6 col-md-6 px-0">
                        <div className="col-auto">
                          {state.download ? (
                            <ExcelFile
                              element={
                                <button className="btn btn-outline-grey w-100">
                                  Download
                                </button>
                              }
                              filename="Advan File"
                            >
                              <ExcelSheet
                                name="Reporting"
                                dataSet={multiDataSet}
                              ></ExcelSheet>
                            </ExcelFile>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="col-auto px-0">
                          <button
                            className="btn-outline-grey btn w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#modalFilter"
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Search
                    onSearch={(e) => {
                      this.setState({ sQuery: e });
                      this.props.getOrder({
                        token: this.props.token,
                        status: "delivered",
                        search: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Document Number</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.isLoading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <LoadData />
                    </td>
                  </tr>
                ) : this.props.incomingOrder.length >= 1 ? (
                  this.props.incomingOrder.map((d, index) => (
                    <tr key={index}>
                      <td>
                        {this.props.pageInfo.page > 1
                          ? (this.props.pageInfo.page - 1) *
                              this.props.pageInfo.limit +
                            (index + 1)
                          : index + 1}
                      </td>
                      <td>{d.items[0].sku}</td>
                      <td>{d.document_number}</td>
                      {/* <td>{d.items[0].name}</td> */}
                      <td>{d.items[0].qty}</td>
                      <td>{d.receiver.name}</td>
                      <td>{this.status(d.status)}</td>
                      {/* <td className="destination">
                        <p>{d.receiver.destination}</p>
                      </td> */}
                      <td>
                        <div className="row w-auto px-3 justify-content-center">
                          <div
                            className="col-auto bg-icon-blue mb-2 me-2"
                            onClick={() => this.showModal(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <span className="material-icons visibility">
                              visibility
                            </span>
                          </div>
                          {/* <div className="col-auto bg-icon-yellow me-2">
                            <span className="material-icons visibility">
                              edit
                            </span>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row justify-content-between top-content align-items-center">
              <Showing
                limit={this.props.pageInfo.limit}
                totalData={this.props.pageInfo.totalData}
                page={this.props.pageInfo.page}
              />
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-auto pe-0">
                    {this.props.pageInfo.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        // pageCount={4}
                        pageCount={this.props.pageInfo.totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        activeClassName="active"
                        activeLinkClassName="active"
                        initialPage={this.props.pageInfo.page - 1}
                        disableInitialCallback={true}
                        onPageChange={(e) => this.getOrderbyPage(e)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  let incomingorder = [];
  var pageInfo = {};
  var error = null;
  var totalData = null;
  var update = false;
  if (state.order.data !== null) {
    if (state.order.data.status) {
      pageInfo.limit = state.order.data.limit;
      pageInfo.totalPage = state.order.data.total_page;
      pageInfo.totalData = state.order.data.total_data;
      pageInfo.page = state.order.data.page;
      incomingorder = state.order.data.data;
      // for (let i = 0; i < state.order.data.data.length; i++) {
      //   if (state.order.data.data[i].status === "incoming") {
      //     incomingorder.push(state.order.data.data[i]);
      //   }
      // }
    }
  }
  if (state.order.error !== null && state.order.error !== undefined) {
    error = state.order.error.message;
  }
  if (totalData !== null && pageInfo.totalData !== undefined) {
    if (totalData !== pageInfo.totalData) {
      update = true;
    } else {
      update = false;
    }
  }
  return {
    pageInfo: pageInfo,
    incomingOrder: incomingorder,
    token: state.auth.dataUser.data.token,
    isLoading: state.order.isLoading,
    error: error,
    totalData: totalData,
    update: update,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (token, limit, page) => dispatch(getOrder(token, limit, page)),
    checkNewData: (token, url) => dispatch(checkNewData(token, url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Done);
