import React, { Component } from "react";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { getOrder, putPickup } from "../store/order/actions";

const videoConstraints = {
  width: 220,
  height: 317,
  facingMode: "user",
};

class ModalFormPickup extends Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state = {
      formData: {
        name: null,
        driver: "",
        vehicle: "",
        police_no: "",
        driver_photo: null,
      },
      imagePath: null,
      openCamera: false,
      selectedName: null,
      message: null,
    };
    this.fileInputRef = React.createRef();
  }
  componentDidMount() {
    this.props.getOrder({
      token: this.props.token,
      status: "packed",
      search: this.state.sQuery,
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.formData.driver_photo !== null) {
      this.props.putPickup({
        data: this.state.formData,
        token: this.props.token,
        id: this.props.data._id,
      });
      this.handleClose();
    }
  };
  handleClose = () => {
    document.getElementById("formpic").reset();
    this.setState({
      selectedName: "reset",
      imagePath: null,
      openCamera: false,
      formData: {
        name: null,
        driver: "",
        vehicle: "",
        police_no: "",
        driver_photo: null,
      },
    });
  };
  handleChange2 = (e) => {
    const { value } = e.target;
    if (value === "lainnya") {
      this.setState({ selectedName: value });
    } else {
      this.setState({
        selectedName: "reset",
        formData: {
          ...this.state.formData,
          name: value,
        },
      });
    }
  };
  handleChange = (e) => {
    console.log("Change");
    const { name, value } = e.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    // if (
    // this.state.formData.driver !== null &&
    // this.state.formData.name !== null &&
    // this.state.formData.police_no !== null &&
    // this.state.formData.vehicle !== null &&
    // this.state.formData.driver !== "" &&
    // this.state.formData.name !== "" &&
    // this.state.formData.police_no !== "" &&
    // this.state.formData.vehicle !== ""
    //     // &&  this.state.formData.driver_photo !== null
    // ) {
    //   this.setState({ update: true });
    // } else {
    // this.setState({ update: false });
    // }
  };
  handleChangeimg = (e) => {
    e.preventDefault();
    let imageFile = e.target.files[0];
    if (imageFile) {
      const localImageUrl = URL.createObjectURL(imageFile);
      const imageObject = new window.Image();

      imageObject.onload = () => {
        imageFile.width = imageObject.naturalWidth;
        imageFile.height = imageObject.naturalHeight;
        // input.onChange(imageFile);
        URL.revokeObjectURL(imageFile);
      };
      imageObject.src = localImageUrl;
      this.setState({
        imagePath: localImageUrl,
        formData: {
          ...this.state.formData,
          driver_photo: imageFile,
        },
      });
    }
  };
  fileInputClicked = () => {
    this.fileInputRef.current.click();
  };
  captureImg = () => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    if (imageSrc !== null) {
      const blob = this.dataURLtoBlob(imageSrc);
      // const file = new File(
      //   imageSrc, "driver_photo.jpg"
      // )
      this.setState({
        message: null,
        imagePath: imageSrc,
        formData: {
          ...this.state.formData,
          driver_photo: blob,
        },
      });
    } else {
      this.setState({
        message: "failed to take photo, check your camera device",
      });
    }
  };
  dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  render() {
    return (
      <div
        className="modal fade"
        id="modalFormPickup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="row w-100 align-items-center gx-0">
                <div className="col-md-4">
                  <span className="material-icons">print</span>
                </div>
                <div className="col-md-4 justify-content-center d-flex">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Detail Order
                  </h5>
                </div>
                <div className="col-md-4 justify-content-end d-flex">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.handleClose()}
                  ></button>
                </div>
              </div>
            </div>
            <form id="formpic" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="modal-body">
                <p className="subtitle mb-2">Courier</p>
                <div className="row">
                  <div className="col-9">
                    <div className="form-group">
                      <label htmlFor="subject">Deliverer Name</label>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        onChange={this.handleChange2}
                        name="selected_name"
                        required
                      >
                        <option defaultValue value="">
                          Select Deliverer Name
                        </option>
                        <option value="Gojek Instant">Gojek Instant</option>
                        <option value="Grab Instant">Grab Instant</option>
                        <option value="Anteraja">Anteraja</option>
                        <option value="J&T">J&T</option>
                        <option value="JNE">JNE</option>
                        <option value="SiCepat">SiCepat</option>
                        <option value="NinjaExpress">NinjaExpress</option>
                        <option value="ShopeeExpress">ShopeeExpress</option>
                        <option value="Kantor POS">Kantor POS</option>
                        <option value="ID Express">ID Express</option>
                        <option value="Tiki">Tiki</option>
                        <option value="lainnya">Lainnya</option>
                      </select>
                      {this.state.selectedName === "lainnya" &&
                      this.state.selectedName !== "reset" ? (
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Deliverer Name"
                          required
                          onChange={this.handleChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Courier Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="driver"
                        name="driver"
                        placeholder="Courier Name"
                        required
                        onChange={this.handleChange}
                        value={this.state.formData.driver}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="subject">Vehicle</label>
                      <input
                        type="text"
                        className="form-control"
                        id="vehicle"
                        name="vehicle"
                        placeholder="Vehicle"
                        required
                        onChange={this.handleChange}
                        value={this.state.formData.vehicle}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Police Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="police_no"
                        name="police_no"
                        placeholder="Police Number"
                        required
                        onChange={this.handleChange}
                        value={this.state.formData.police_no}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group mb-0 ">
                      <label htmlFor="subject">Driver Photo</label>
                      <div>
                        {/* <input
                              type="file"
                              className="form-control2"
                              accept="image/jpeg, image/png;capture=camera"
                              //   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              id="driver_photo"
                              name="driver_photo"
                              onChange={(e) => this.handleChangeimg(e)}
                              required
                            /> */}
                        {this.state.openCamera ? (
                          <div>
                            {this.state.formData.driver_photo === null ? (
                              <div>
                                <Webcam
                                  audio={false}
                                  height={317}
                                  ref={this.webcamRef}
                                  screenshotFormat="image/jpeg"
                                  width={220}
                                  videoConstraints={videoConstraints}
                                  className="camera-area"
                                />
                                {this.state.message !== null ? (
                                  <p className="error-message">
                                    {this.state.message}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {this.state.message !== null ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary shadow-none"
                                    style={{
                                      width: "220px",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        imagePath: null,
                                        openCamera: false,
                                        message: null,
                                        formData: {
                                          ...this.state.formData,
                                          driver_photo: null,
                                        },
                                      })
                                    }
                                  >
                                    Back
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary shadow-none"
                                    style={{
                                      width: "220px",
                                    }}
                                    onClick={() => this.captureImg()}
                                  >
                                    Take Photo
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={this.state.imagePath}
                                  alt="Driver"
                                  className="img-responsive mb-2"
                                  height="317px"
                                  width="220px"
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    width: "220px",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      imagePath: null,
                                      formData: {
                                        ...this.state.formData,
                                        driver_photo: null,
                                      },
                                    })
                                  }
                                >
                                  Retake
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="d-flex justify-content-center align-items-center text-center"
                            style={{
                              height: `317px`,
                              width: `220px`,
                              backgroundColor: `#cccccc`,
                              padding: `18px`,
                            }}
                          >
                            {this.state.imagePath !== null ? (
                              <img
                                src={this.state.imagePath}
                                alt="Driver"
                                className="img-responsive mb-2"
                                height="317px"
                                width="220px"
                                role="button"
                                onClick={() =>
                                  this.setState({
                                    imagePath: null,
                                    formData: {
                                      ...this.state.formData,
                                      driver_photo: null,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <div className="row">
                                <div className="col-12 mb-3">
                                  <button
                                    onClick={() =>
                                      this.setState({
                                        openCamera: true,
                                      })
                                    }
                                    className="btn btn-dark w-100"
                                  >
                                    Take Photo
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    onClick={() => this.fileInputClicked()}
                                    className="btn btn-dark w-100"
                                  >
                                    <input
                                      ref={this.fileInputRef}
                                      style={{
                                        display: `none`,
                                      }}
                                      accept="image/jpeg, image/png"
                                      type="file"
                                      onChange={(e) => this.handleChangeimg(e)}
                                      required
                                    />
                                    Upload Photo
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* <WebcamCapture/> */}
                      </div>
                      {/* <input
                        type="file"
                        className="form-control2"
                        accept="image/jpeg, image/png"
                        //   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        id="driver_photo"
                        name="driver_photo"
                        onChange={(e) => this.handleChangeimg(e)}
                        required
                      />
                      <p className="label">*File must be .png, .jpeg</p> */}
                    </div>
                    {/* <div className="col-6 justify-content-end d-flex">
                    <div
                      style={{
                        maxHeight: `196px`,
                        maxWidth: `196px`,
                      }}
                    >
                      <img
                        src={this.state.imagePath}
                        alt="Driver"
                        className="img-responsive"
                        height="196px"
                        width="196px"
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
                {/* <p className="subtitle mb-2">Note</p>

                <div className="form-group">
                  <label htmlFor="subject">Note</label>
                  <textarea
                    rows="3"
                    className="form-control-textarea"
                    id="note"
                    name="note"
                    placeholder="Add Note"
                    required
                    onChange={this.handleChange}
                  />
                </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-dark w-100"
                  data-bs-dismiss={
                    this.state.formData.name !== null &&
                    this.state.formData.driver !== "" &&
                    this.state.formData.name !== "" &&
                    this.state.formData.police_no !== "" &&
                    this.state.formData.vehicle !== "" &&
                    this.state.formData.driver_photo !== null
                      ? `modal`
                      : `no`
                  }
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.dataUser.data.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putPickup: (data) => dispatch(putPickup(data)),
    getOrder: (token, limit, page) => dispatch(getOrder(token, limit, page)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalFormPickup);
