import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BoxIcon, ListIcon, MemberIcon } from "./IconsMenu";
import Logo from "../assets/LogoD.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { pathname: "/", show: false };
  }
  componentDidMount() {
    if (this.state.pathname !== window.location.pathname) {
      this.updateNav();
    }
  }
  componentDidUpdate() {
    if (this.state.pathname !== window.location.pathname) {
      this.updateNav();
    }
  }
  updateNav = () => {
    this.setState({
      pathname: window.location.pathname,
    });
  };
  showHide = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    const state = this.state;
    const pages = [
      {
        path: "/hah",
      },
      {
        path: "/",
      },
      {
        path: "/packaging",
      },
      {
        path: "/pickup",
      },
      {
        path: "/done",
      },
      {
        path: "/masterdata",
      },
      {
        path: "/member",
      },
      // {
      //   path: "/report",
      // },
    ];
    return (
      <>
        <div
          className="d-flex flex-column flex-shrink-0 bg-dark overflow-scroll"
          style={{
            width: 305,
          }}
        >
          <div
            to="/"
            className="align-items-end mb-5 mt-3 ms-3 d-grid justify-content-between"
          >
            <Link to="/" className="text-decoration-none">
              <div className="d-flex align-items-center">
                <img
                  className="img-responsive"
                  src={Logo}
                  alt="logo"
                  width="250px"
                  // height="52px"
                />
                {/* <div className="title-section mx-2">
                  <p className="sidebar-title mb-0">ADVAN</p>
                </div> */}
              </div>
            </Link>
          </div>
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item dropdown">
              <button
                className={`nav-link dropdown-toggle w-100 ${
                  state.pathname === pages[1].path ||
                  state.pathname === pages[2].path ||
                  state.pathname === pages[3].path ||
                  state.pathname === pages[4].path
                    ? `active`
                    : ``
                }`}
                // aria-current="page"
                onClick={this.showHide}
                id="navbarDropdown"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
              >
                <BoxIcon
                  opacity={
                    state.pathname === pages[1].path ||
                    state.pathname === pages[2].path ||
                    state.pathname === pages[3].path ||
                    state.pathname === pages[4].path
                      ? 1
                      : 0.7
                  }
                />
                Order
              </button>
              <ul
                className={`dropdown-menu ps-4 w-100 ${
                  this.state.show ? `show` : ``
                } ${
                  state.pathname === pages[1].path ||
                  state.pathname === pages[2].path ||
                  state.pathname === pages[3].path ||
                  state.pathname === pages[4].path
                    ? `active`
                    : ``
                }`}
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    to="/"
                    className={`nav-link dropdown-item ${
                      state.pathname === pages[1].path ? `active` : ``
                    }`}
                    onClick={this.updateNav}
                  >
                    Incoming Order
                  </Link>
                </li>
                <li>
                  <Link
                    to="/packaging"
                    className={`nav-link dropdown-item ${
                      state.pathname === pages[2].path ? `active` : ``
                    }`}
                  >
                    Packaging
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pickup"
                    className={`nav-link dropdown-item ${
                      state.pathname === pages[3].path ? `active` : ``
                    }`}
                  >
                    Pick Up
                  </Link>
                </li>
                <li>
                  <Link
                    to="/done"
                    className={`nav-link dropdown-item ${
                      state.pathname === pages[4].path ? `active` : ``
                    }`}
                  >
                    Done
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                to="/masterdata"
                className={`nav-link ${
                  state.pathname === pages[5].path ? `active` : ``
                }`}
              >
                <ListIcon
                  opacity={state.pathname === pages[5].path ? 1 : 0.7}
                />
                Master Data
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/report"
                className={`nav-link ${
                  state.pathname === pages[7].path ? `active` : ``
                }`}
              >
                <ReportIcon
                  opacity={state.pathname === pages[7].path ? 1 : 0.7}
                />
                Report
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                to="/member"
                className={`nav-link ${
                  state.pathname === pages[6].path ? `active` : ``
                }`}
              >
                <MemberIcon
                  opacity={state.pathname === pages[6].path ? 1 : 0.7}
                />
                Member
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
export default Sidebar;
