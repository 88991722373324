import {
  GET_ORDER_FAILED,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  PUT_PACKAGING_FAILED,
  PUT_PACKAGING_REQUEST,
  PUT_PACKAGING_SUCCESS,
  PUT_STATUS_PACKAGING_FAILED,
  PUT_STATUS_PACKAGING_REQUEST,
  PUT_STATUS_PACKAGING_SUCCESS,
  PUT_PICKUP_FAILED,
  PUT_PICKUP_REQUEST,
  PUT_PICKUP_SUCCESS,
  PUT_STATUS_ORDER_FAILED,
  PUT_STATUS_ORDER_REQUEST,
  PUT_STATUS_ORDER_SUCCESS,
  PUT_STATUS_ALL_ORDER_FAILED,
  PUT_STATUS_ALL_ORDER_REQUEST,
  PUT_STATUS_ALL_ORDER_SUCCESS,
  CHECK_NEW_DATA,
} from "./type";

const initialState = {
  isLoading: false,
  data: null,
  error: "",
  postResponse: null,
  packagingResponse: null,
  packagingError: null,
  pickupResponse: null,
  checkNewData: null,
};
const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_SUCCESS:
      return {
        isLoading: false,
        data: action.payload,
        error: "",
      };
    case GET_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PUT_STATUS_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postResponse: action.payload,
        error: "",
      };
    case PUT_STATUS_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_STATUS_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PUT_STATUS_ALL_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postResponse: action.payload,
        error: "",
      };
    case PUT_STATUS_ALL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_STATUS_ALL_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PUT_PACKAGING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        packagingResponse: action.payload,
        error: "",
      };
    case PUT_PACKAGING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_PACKAGING_FAILED:
      return {
        ...state,
        isLoading: false,
        packagingError: action.payload,
      };
    case PUT_STATUS_PACKAGING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        packagingResponse: action.payload,
        error: "",
      };
    case PUT_STATUS_PACKAGING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_STATUS_PACKAGING_FAILED:
      return {
        ...state,
        isLoading: false,
        packagingError: action.payload,
      };
    case PUT_PICKUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pickupResponse: action.payload,
        error: "",
      };
    case PUT_PICKUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_PICKUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CHECK_NEW_DATA:
      return {
        ...state,
        isLoading: false,
        checkNewData: action.payload,
      };

    default:
      return state;
  }
};
export default order;
