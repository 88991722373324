import React from "react";
import { useState } from "react";
// import debounce from "lodash.debounce";

const Search = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");
  const handleChange = (e) => {
    const text = e.target.value;
    if (text === "") {
      onSearch(text);
    } else {
      setSearchText(text);
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      onSearch(searchText);
    }
  };
  return (
    <div className="col-md-6 col-6 align-items-center pe-0">
      <div className="input-group search">
        <span className="material-icons input-group-text">search</span>
        <input
          type="search"
          onChange={handleChange}
          onKeyPress={handleEnter}
          placeholder="Search Here"
          className="form-control"
          aria-label="Username"
          aria-describedby="addon-wrapping"
        />
      </div>
    </div>
  );
};

export default Search;
