import React, { Component } from "react";
import { connect } from "react-redux";
import { postMember } from "../store/member/actions";

class ModalAddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: null,
        password: null,
        level: "Admin",
        name: null,
      },
      update: false,
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.postMember({
      data: this.state.formData,
      token: this.props.token,
    });
    this.handleClose();
  };
  handleClose = () => {
    document.getElementById("addmember").reset();
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
    if (
      this.state.formData.email !== null &&
      this.state.formData.password !== null &&
      this.state.formData.level !== null &&
      this.state.formData.name !== null &&
      this.state.formData.email !== "" &&
      this.state.formData.name !== "" &&
      this.state.formData.password !== "" &&
      this.state.formData.level !== ""
      //   &&  this.state.formData.note !== null
    ) {
      this.setState({ update: true });
    } else {
      this.setState({ update: false });
    }
  };
  render() {
    return (
      <div
        className="modal fade"
        id="modaladdmember"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content w-50">
            <div className="modal-header">
              <div className="row w-100 align-items-center gx-0">
                <div className="col-md-4">
                  <span className="material-icons">print</span>
                </div>
                <div className="col-md-4 justify-content-center d-flex">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Add Member
                  </h5>
                </div>
                <div className="col-md-4 justify-content-end d-flex">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.handleClose()}
                  ></button>
                </div>
              </div>
            </div>
            <form id="addmember" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="modal-body">
                {/* <p className="subtitle mb-2">Name</p> */}

                <div className="form-group">
                  <label htmlFor="subject">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="subject">Level</label>
                  <input
                    type="text"
                    className="form-control"
                    id="level"
                    name="level"
                    placeholder="Level"
                    onChange={this.handleChange}
                    required
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="subject">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-dark w-50"
                  data-bs-dismiss={this.state.update ? `modal` : `no`}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.dataUser.data.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postMember: (data) => dispatch(postMember(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalAddMember);
