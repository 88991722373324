import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectRoute = ({
  component: Component,
  redirectPath,
  login,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectPath === "login") {
          if (login) {
            return <Component {...rest} {...props} />;
          } else {
            return <Redirect to={`/${redirectPath}`} />;
          }
        } else if (redirectPath === "") {
          if (!login) {
            return <Component {...rest} {...props} />;
          } else {
            return <Redirect to={`/${redirectPath}`} />;
          }
        }
      }}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.auth.isLogin,
  };
};
export default connect(mapStateToProps, null)(ProtectRoute);
