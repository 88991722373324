import axios from "../config";
import {
  GET_IMEI_SUCCESS,
  POST_MASTER_FAILED,
  POST_MASTER_REQUEST,
  POST_MASTER_SUCCESS,
  GET_MASTER_FAILED,
  GET_MASTER_REQUEST,
  GET_MASTER_SUCCESS,
} from "./type";

export const postMaster = ({ fileMaster, token }) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", fileMaster, fileMaster.name);
    dispatch(postMasterRequest());
    axios
      .post("item", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
          // ...formData.getHeaders()
        },
      })
      .then((value) => {
        if (value.data.status) {
          dispatch(postMasterSuccess(value.data));
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(postMasterFailed(error.response.data));
        } else {
          dispatch(postMasterFailed(error));
        }
      });
  };
};

export const getImei = ({ token }) => {

  return (dispatch) => {
    // dispatch(postMasterRequest());
    axios
      .get("item?show=all&is_available=true", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((value) => {
        dispatch(countryberhasil(value.data));
      })
      .catch(function (error) {
        // dispatch(countryfailed(error));
      })
      .then(function () {
        // always executed
      });
  };
};
export const countryberhasil = (data) => {
  var dataName = [];
  for (let i = 0; i < data.data.length; i++) {
    dataName[i] = {
      // ...data,
      id: data.data[i]._id,
      label: data.data[i].imei,
      msisdn: data.data[i].msisdn,
    };
  }
  return {
    type: GET_IMEI_SUCCESS,
    payload: dataName,
  };
};

export const postMasterSuccess = (dataUser) => {
  return {
    type: POST_MASTER_SUCCESS,
    payload: dataUser,
  };
};
export const postMasterRequest = () => {
  return { type: POST_MASTER_REQUEST };
};
export const postMasterFailed = (error) => {
  return {
    type: POST_MASTER_FAILED,
    payload: error,
  };
};

export const getMaster = ({
  token,
  limit = 10,
  page = 1,
  search = "",
  available,
}) => {
  return (dispatch) => {
    dispatch(gettMasterRequest());
    axios
      .get(
        `item?per_page=${limit}&page=${page}&search=${search}${
          available !== undefined ? `&is_available=${available}` : ``
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            dispatch(getMasterSuccess(response.data));
          }
        }
      })
      .catch((error) => {
        dispatch(gettMasterFailed(error));
      });
  };
};

export const getMasterSuccess = (dataUser) => {
  return {
    type: GET_MASTER_SUCCESS,
    payload: dataUser,
  };
};
export const gettMasterRequest = () => {
  return { type: GET_MASTER_REQUEST };
};
export const gettMasterFailed = (error) => {
  return {
    type: GET_MASTER_FAILED,
    payload: error,
  };
};
