import axios from "../config";
import {
  GET_MEMBER_FAILED,
  GET_MEMBER_REQUEST,
  GET_MEMBER_SUCCESS,
  POST_MEMBER_FAILED,
  POST_MEMBER_REQUEST,
  POST_MEMBER_SUCCESS,
} from "./type";

export const postMember = ({ data, token }) => {
  return (dispatch) => {
    dispatch(postMemberRequest());
    axios
      .post("user/create", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((value) => {
        if (value.data.status) {
          dispatch(postMemberSuccess(value.data));
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(postMemberFailed(error.response.data));
        } else {
          dispatch(postMemberFailed(error));
        }
      
      });
  };
};

export const getMember = ({ token, limit = 10, page = 1, search = "" }) => {
  return (dispatch) => {
    dispatch(getMemberRequest());
    axios
      .get(`user/list?per_page=${limit}&page=${page}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            // if(page===1){
            //   dispatch(orderSuccess(data.Incoming));
            // }else{
            //   dispatch(orderSuccess(data.Incoming2));
            // }
            dispatch(getMemberSuccess(response.data));
          }
        }
      })
      .catch(function (error) {
        dispatch(getMemberFailed(error));
      })
      .then(function () {
        // always executed
      });
  };
};

export const getMemberSuccess = (dataUser) => {
  return {
    type: GET_MEMBER_SUCCESS,
    payload: dataUser,
  };
};
export const getMemberRequest = () => {
  return { type: GET_MEMBER_REQUEST };
};
export const getMemberFailed = (error) => {
  return {
    type: GET_MEMBER_FAILED,
    payload: error,
  };
};

export const postMemberSuccess = (dataUser) => {
  return {
    type: POST_MEMBER_SUCCESS,
    payload: dataUser,
  };
};
export const postMemberRequest = () => {
  return { type: POST_MEMBER_REQUEST };
};
export const postMemberFailed = (error) => {
  return {
    type: POST_MEMBER_FAILED,
    payload: error,
  };
};
