import React from "react";

const Showing = ({ limit, totalData, page }) => {
  var page2 = 1;
  if (page >= 2) {
    page2 = ((page - 1) * limit )+ 1;
    limit = page * limit;
  }
  if (totalData === 0) {
    page2 = 0;
    limit = 0;
  }
  if (totalData < limit) {
    limit = totalData;
  }
  return (
    <div className="col-md-6 info-data col-6">
      Showing {page2} to {limit} of {totalData} entries
    </div>
  );
};

export default Showing;
