
export const LoadingSend = () => {
    return (
      <div className="loading-layout">
        <div className="row">
          <div className="col align-self-center">
            <div className="loading-send"></div>
            <div className="mt-3">
              <p>Sending</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export const LoadData = () => {
    return (
      <div className="loaddata-layout">
        <div className="row">
          <div className="col align-self-center">
            <div className="loading-data"></div>
            {/* <div className="mt-3">
              <p>Load Data</p>
            </div> */}
          </div>
        </div>
      </div>
    );
  };