import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadingSend } from "../../layouts/Loading";
import { loginAction } from "../../store/auth/actions";
import Logo from "../../assets/LogoL.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: null,
        password: null,
      },
      visibilty: false,
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.loginAction(this.state.formData);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };
  vision = () => {
    this.setState({
      visibilty: !this.state.visibilty,
    });
  };
  render() {
    const props = this.props;
    return (
      <>
        {props.authData.isLoading ? <LoadingSend /> : ""}
        <div className="row mx-0 w-100 vh-100 align-items-center justify-content-center">
          <div className="login">
            <div className="w-100 d-flex justify-content-center">
              <img src={Logo} alt="logo" width="300px" className="img-fluid" />
            </div>
            <p className="title-text">Login</p>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group mb-3">
                <label htmlFor="subject">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="subject">Password</label>
                <div className="input-group">
                  <input
                    type={this.state.visibilty ? `text` : `password`}
                    className="form-control2"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required
                    onChange={this.handleChange}
                  />
                  <span
                    role="button"
                    className="material-icons input-group-text"
                    onClick={this.vision}
                  >
                    {this.state.visibilty ? `visibility` : `visibility_off`}
                  </span>
                </div>
              </div>
              <button type="submit" className="btn btn-dark w-100">
                Login
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authData: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (form) => dispatch(loginAction(form)),
    // enterPage : () => dispatch(enterPage())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
