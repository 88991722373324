import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center text-center">
      <div className="row w-md-25 w-80 justify-content-center ">
        <h1>Page Not Found</h1>
        <div className="col-md-6 col-auto mt-4 ">
          <Link to="/" className="text-decoration-none btn-dark p-3 rounded-3"> Back To Home</Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
