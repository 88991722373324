import React, { Component } from "react";
import Header from "../../layouts/Header";
import { ModalDetail } from "../../layouts/Modal";
import { Breadcrumb } from "../../layouts/Breadcrumb";
import { connect } from "react-redux";
import { checkNewData, getOrder } from "../../store/order/actions";
import ReactPaginate from "react-paginate";
import Showing from "../../layouts/Showing";
import Search from "../../layouts/Search";
import ModalFormPickup from "../../layouts/ModalPickup";
import swal from "sweetalert";
import { LoadData } from "../../layouts/Loading";
import ReactToPrint from "react-to-print";
import Print from "../../layouts/Print";

class Pickup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailData: [],
      dataPrint: null,
      sQuery: "",
      editedData: [],
    };
    // setInterval(() => {
    //   this.props.checkNewData({
    //     token: this.props.token,
    //     url: "order?status=packed",
    //   });
    // }, 20000);
  }
  onPrint = (value) => {
    this.setState({
      dataPrint: value,
    });
    return "oke";
  };
  showModal = (data) => {
    this.setState({
      detailData: data,
    });
  };
  componentDidMount() {
    this.props.getOrder({
      token: this.props.token,
      status: "packed",
      search: this.state.sQuery,
    });
  }
  status = (status) => {
    switch (status) {
      case "packed":
        status = status.toUpperCase();
        break;
      default:
        status = status.toUpperCase();
        break;
    }
    return <p className={status}>{status}</p>;
  };
  getOrderbyPage = (currentPage) => {
    this.props.getOrder({
      status: "packed",
      token: this.props.token,
      page: currentPage.selected + 1,
      search: this.state.sQuery,
    });
  };
  getOrderbyLimit = (limit) => {
    this.props.getOrder({
      token: this.props.token,
      limit: limit.target.value,
      status: "packed",
      search: this.state.sQuery,
    });
  };
  dateFormat = (date) => {
    var newDate = date.split(".");
    return newDate[0].replace("T", " ");
  };

  onEdit = (data) => {
    this.setState({ editedData: data });
  };
  render() {
    const state = this.state;
    if (this.props.error !== null && this.props.error !== undefined) {
      swal("Something Wrong!", `${this.props.error}`, "error", {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          text: "Reload",
        },
      }).then((value) => {
        if (value) {
          this.props.getOrder({
            token: this.props.token,
            status: "packed",
            search: this.state.sQuery,
          });
        }
      });
    }
    if (
      this.props.pickupResponse !== null &&
      this.props.pickupResponse !== undefined
    ) {
      if (this.props.pickupResponse.status) {
        swal("Great!", `${this.props.pickupResponse.message}`, "success").then(
          (value) =>
            this.props.getOrder({
              token: this.props.token,
              status: "packed",
              search: this.state.sQuery,
            })
        );
      } else {
        swal(
          "Something Wrong!",
          `${this.props.pickupResponse.message}`,
          "error"
        ).then((value) =>
          this.props.getOrder({
            token: this.props.token,
            status: "packed",
            search: this.state.sQuery,
          })
        );
      }
    }
    if (this.props.update) {
      this.props.getOrder({
        token: this.props.token,
        status: "packed",
        search: this.state.sQuery,
      });
    }
    return (
      <>
        <Header title="Pickup" />
        <ModalDetail data={state.detailData} />
        <ModalFormPickup data={state.editedData} />
         {/* Start Print Document */}
         <div
          className="modal fade"
          id="modalPrint"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row justify-content-center">
                  <div className="col text-center">
                    <div className="swal-icon swal-icon--warning">
                      <span className="swal-icon--warning__body">
                        <span className="swal-icon--warning__dot"></span>
                      </span>
                    </div>
                    <h3 className="modal-title mb-2" id="staticBackdropLabel">
                      Are You Sure?
                    </h3>
                    <h6>You will Print the Document</h6>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row w-50">
                  <div className="col-6 pe-0">
                    <button
                      className="col-auto bg-icon-grey me-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6 pe-0">
                    <ReactToPrint
                      documentTitle="Packaging"
                      trigger={() => (
                        <button
                          className="col-auto yess"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          type="button"
                        >
                          OK
                        </button>
                      )}
                      content={() => this.componentRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Print Document */}
        <div className="base">
        <div className="w-50 d-none overflow-auto">
            <div id="printme">halo</div>
            <Print
              ref={(el) => (this.componentRef = el)}
              // data={this.props.incomingOrder}
              data={state.dataPrint}
            />
          </div>
          <Breadcrumb subtitle="Pickup" />
          <div className="table-responsive base-table">
            <div className="row mb-4 top-content">
              <div className="col-md-6 col-6">
                <div className="col-md-6 col-8">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-3">show</div>
                    <div className="col-md-5 col-5">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => this.getOrderbyLimit(e)}
                      >
                        <option defaultValue value="10">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-4">entires</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end align-items-center">
                  <div className="col-md-4 col-6 align-self-end">
                    <div className="row justify-content-end align-items-center">
                      {/* <div className="col-md-4 col-4">Filter:</div>
                      <div className="col-md-8 col-8">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option defaultValue>Status</option>
                          <option value="1">Name</option>
                          <option value="2">SKU</option>
                          <option value="3">Quantity</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                  <Search
                    onSearch={(e) => {
                      this.setState({ sQuery: e });
                      this.props.getOrder({
                        status: "packed",
                        token: this.props.token,
                        search: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Document Number</th>
                  <th scope="col">Delivery Type</th>
                  {/* <th scope="col">Estimation</th> */}
                  <th scope="col">Receiver</th>
                  <th scope="col">Status</th>
                  <th style={{ maxWidth: `171px` }} scope="col">
                    Destination
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.isLoading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <LoadData />
                    </td>
                  </tr>
                ) : this.props.incomingOrder.length >= 1 ? (
                  this.props.incomingOrder.map((d, index) => (
                    <tr key={index}>
                      <td>
                        {this.props.pageInfo.page > 1
                          ? (this.props.pageInfo.page - 1) * this.props.pageInfo.limit + (index + 1)
                          : index + 1}
                      </td>
                      <td>{d.document_number}</td>
                      <td>{d.delivery.type}</td>
                      {/* <td>{d.items[0].name}</td> */}
                      {/* <td>{this.dateFormat(d.estimation_send_date)}</td> */}
                      <td>{d.receiver.name}</td>
                      <td>{this.status(d.status)}</td>
                      <td className="destination">
                        <p>{d.receiver.destination}</p>
                      </td>
                      <td>
                        <div className="row w-auto px-3 justify-content-center">
                          <div
                            className="col-auto bg-icon-blue mb-2 me-2"
                            onClick={() => this.showModal(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <span className="material-icons visibility">
                              visibility
                            </span>
                          </div>
                          <div
                            className="col-auto bg-icon-grey me-2"
                            role="button"
                            onClick={() => this.onPrint([d])}
                            data-bs-toggle="modal"
                            data-bs-target="#modalPrint"
                          >
                            <span className="material-icons visibility">
                              print
                            </span>
                          </div>
                          <div
                            className="col-auto bg-icon-yellow me-2"
                            onClick={() => this.onEdit(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#modalFormPickup"
                          >
                            <span className="material-icons visibility">
                              edit
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row justify-content-between top-content align-items-center">
              <Showing
                limit={this.props.pageInfo.limit}
                totalData={this.props.pageInfo.totalData}
                page={this.props.pageInfo.page}
              />
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-auto pe-0">
                    {this.props.pageInfo.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        // pageCount={4}
                        pageCount={this.props.pageInfo.totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        activeClassName="active"
                        activeLinkClassName="active"
                        initialPage={this.props.pageInfo.page - 1}
                        disableInitialCallback={true}
                        onPageChange={(e) => this.getOrderbyPage(e)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let incomingorder = [];
  var pageInfo = {};
  var pickupResponse = null;
  let error = null;
  var totalData = null;
  var update = false;
  if (state.order.data !== null) {
    if (state.order.data.status) {
      pageInfo.limit = state.order.data.limit;
      pageInfo.totalPage = state.order.data.total_page;
      pageInfo.totalData = state.order.data.total_data;
      pageInfo.page = state.order.data.page;
      incomingorder = state.order.data.data;
      // for (let i = 0; i < state.order.data.data.length; i++) {
      //   if (state.order.data.data[i].status === "incoming") {
      //     incomingorder.push(state.order.data.data[i]);
      //   }
      // }
    }
  }
  if (!state.order.isLoading) {
    if (state.order.error !== null) {
      error = state.order.error.message;
    }
    if (
      state.order.pickupResponse !== null &&
      state.order.pickupResponse !== undefined
    ) {
      pickupResponse = state.order.pickupResponse;
    }
  }

  if (totalData !== null && pageInfo.totalData !== undefined) {
    if (totalData !== pageInfo.totalData) {
      update = true;
    } else {
      update = false;
    }
  }
  return {
    pageInfo: pageInfo,
    incomingOrder: incomingorder,
    token: state.auth.dataUser.data.token,
    pickupResponse: pickupResponse,
    isLoading: state.order.isLoading,
    error: error,
    totalData: totalData,
    update: update,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (token, limit, page) => dispatch(getOrder(token, limit, page)),
    checkNewData: (token, url) => dispatch(checkNewData(token, url)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pickup);
