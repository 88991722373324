import { combineReducers } from "redux";
import auth from "./auth/reducer";
import { master } from "./master/reducer";
import { member } from "./member/reducer";
import order from "./order/reducer";

export default combineReducers({
  auth: auth,
  order:order,
  master:master,
  member:member
});
