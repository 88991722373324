export const POST_MASTER_SUCCESS = "POST_MASTER_SUCCESS";
export const POST_MASTER_REQUEST = "POST_MASTER_REQUEST";
export const POST_MASTER_FAILED = "POST_MASTER_FAILED";
export const GET_MASTER_SUCCESS = "GET_MASTER_SUCCESS";
export const GET_MASTER_REQUEST = "GET_MASTER_REQUEST";
export const GET_MASTER_FAILED = "GET_MASTER_FAILED";
export const GET_IMEI_SUCCESS = "GET_IMEI_SUCCESS";
export const GET_IMEI_FAILED = "GET_IMEI_FAILED";
// export const PUT_ORDER_SUCCESS = "PUT_ORDER_SUCCESS";
// export const PUT_ORDER_REQUEST = "PUT_ORDER_REQUEST";
// export const PUT_ORDER_FAILED = "PUT_ORDER_FAILED";
