import storage from "redux-persist/lib/storage";
import combineReducers from "./index";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "advan",
  storage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, combineReducers);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
