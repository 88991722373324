import React, { Component } from "react";
import Header from "../../layouts/Header";
import { Breadcrumb } from "../../layouts/Breadcrumb";
import { ModalDetail } from "../../layouts/Modal";
import dummyData from "../../assets/data/dummyData.json";
import { connect } from "react-redux";
import {
  checkNewData,
  getOrder,
  putStatusAllOrder,
  putStatusOrder,
} from "../../store/order/actions";
import ReactPaginate from "react-paginate";
import Showing from "../../layouts/Showing";
import swal from "sweetalert";
import { LoadData } from "../../layouts/Loading";
import Search from "../../layouts/Search";
import { getImei } from "../../store/master/actions";

class Incoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      data: dummyData,
      detailData: [],
      sQuery: "",
      total_data: null,
    };
    setInterval(() => {
      this.props.checkNewData({
        token: this.props.token,
        url: "order?status=incoming",
      });
    }, 10000);
    // 100000 = 10 detik
  }
  componentDidMount() {
    this.props.getOrder({ token: this.props.token, search: this.state.sQuery });
    this.props.getImei({ token: this.props.token });
  }
  showModal = (data) => {
    this.setState({
      detailData: data,
    });
  };
  status = (status) => {
    switch (status) {
      case "incoming":
        status = status.toUpperCase();
        break;
      default:
        status = status.toUpperCase();
        break;
    }
    return <p className={status}>{status}</p>;
  };
  getOrderbyPage = (currentPage) => {
    this.props.getOrder({
      token: this.props.token,
      page: currentPage.selected + 1,
      limit: this.props.pageInfo.limit,
      search: this.state.sQuery,
    });
  };
  getOrderbyLimit = (limit) => {
    this.props.getOrder({
      token: this.props.token,
      limit: limit.target.value,
      status: "incoming",
      search: this.state.sQuery,
    });
  };
  updateStatusBatch = (data) => {
    swal(
      "Are You Sure?",
      "You will APPROVE the purchase of the item",
      "warning",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: true,
      }
    ).then((value) => {
      if (value) {
        this.props.putStatusOrder({ id: data._id, token: this.props.token });
      }
    });
  };
  updateStatusAll = (data) => {
    var arrID = [];
    for (let i = 0; i < data.length; i++) {
      arrID[i] = data[i]._id;
    }
    swal(
      "Are You Sure?",
      "You will APPROVE the purchase of the item",
      "warning",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: true,
      }
    ).then((value) => {
      if (value) {
        this.props.putStatusAllOrder({ ids: arrID, token: this.props.token });
      }
    });
  };

  // page = () => {
  //   var arrayPage = [];
  //   // for (let i = 0; i < 2; i++) {
  //   for (let i = 0; i < this.props.pageInfo.totalPage; i++) {
  //     arrayPage.push(
  //       <li className="page-item" key={i}>
  //         <button
  //           className={`page-link ${
  //             this.state.page === i + 1 ? `active` : ``
  //             // this.props.pageInfo.page === i + 1 ? `active` : ``
  //           }`}
  //           onClick={() => this.getOrderbyPage(i + 1)}
  //         >
  //           {i + 1}
  //         </button>
  //       </li>
  //     );
  //   }
  //   return arrayPage;
  // };

  render() {
    const state = this.state;
    if (this.props.error !== null && this.props.error !== undefined) {
      swal("Something Wrong!", `${this.props.error}`, "error", {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: {
          text: "Reload",
        },
      }).then((value) => {
        if (value) {
          this.props.getOrder({
            token: this.props.token,
            search: this.state.sQuery,
          });
        }
      });
    }
    if (
      this.props.postResponse !== null &&
      this.props.postResponse !== undefined
    ) {
      if (this.props.postResponse.status) {
        swal("Great!", `${this.props.postResponse.message}`, "success").then(
          (value) =>
            this.props.getOrder({
              token: this.props.token,
              search: this.state.sQuery,
            })
        );
      } else {
        swal(
          "Something Wrong!",
          `${this.props.postResponse.message}`,
          "error"
        ).then((value) =>
          this.props.getOrder({
            token: this.props.token,
            search: this.state.sQuery,
          })
        );
      }
    }
    if (this.props.update) {
      this.props.getOrder({
        token: this.props.token,
        search: this.state.sQuery,
      });
    }
    return (
      <>
        <Header title="Incoming Order" />
        <ModalDetail data={state.detailData} />

        <div className="base">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <Breadcrumb subtitle="Incoming Order" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <p className="stok-info">Stok :</p>
              <div className="stok">{this.props.stok}</div>
            </div>
          </div>
          <div className="table-responsive base-table">
            <div className="row mb-4 top-content">
              <div className="col-md-6 col-6">
                <div className="row">
                  <div className="col-md-6 col-8">
                    <div className="row align-items-center">
                      <div className="col-md-3 col-3">show</div>
                      <div className="col-md-5 col-5">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => this.getOrderbyLimit(e)}
                        >
                          <option defaultValue value="10">
                            10
                          </option>
                          <option value="25">25</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <div className="col-md-4 col-4">entires</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end align-items-center">
                  <div className="col-md-6 col-6 align-self-end">
                    <div className="row justify-content-end align-items-center">
                      {/* <div className="col-md-4 col-4">Filter:</div>
                      <div className="col-md-8 col-8">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="1">Name</option>
                          <option value="2">SKU</option>
                          <option defaultValue value="3">
                            Quantity
                          </option>
                        </select>
                      </div> */}

                      {this.props.incomingOrder.length > 0 ? (
                        <div className="col-auto">
                          <button
                            className="btn-outline-grey btn"
                            onClick={() =>
                              this.updateStatusAll(this.props.incomingOrder)
                            }
                          >
                            <div className="col d-flex">
                              <span className="material-icons visibility">
                                done
                              </span>
                              <div className="all-text">Accept All Display</div>
                            </div>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Search
                    onSearch={(e) => {
                      this.setState({ sQuery: e });
                      this.props.getOrder({
                        token: this.props.token,
                        search: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">SKU</th>
                  <th scope="col">Document Number</th>
                  {/* <th scope="col">Barang</th> */}
                  <th scope="col">Quantity</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.isLoading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <LoadData />
                    </td>
                  </tr>
                ) : this.props.incomingOrder.length >= 1 ? (
                  this.props.incomingOrder.map((d, index) => (
                    <tr key={index}>
                      <td>
                        {this.props.pageInfo.page > 1
                          ? (this.props.pageInfo.page - 1) * this.props.pageInfo.limit + (index + 1)
                          : index + 1}
                      </td>
                      <td>{d.items[0].sku}</td>
                      <td>{d.document_number}</td>
                      {/* <td>{d.items[0].name}</td> */}
                      <td>{d.items[0].qty}</td>
                      <td>{d.receiver.name}</td>
                      <td>{this.status(d.status)}</td>
                      <td>
                        <div className="row w-auto px-3 justify-content-center">
                          <div
                            className="col-auto bg-icon-blue mb-2 me-2"
                            onClick={() => this.showModal(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <span className="material-icons visibility">
                              visibility
                            </span>
                          </div>
                          <div
                            className="col-auto bg-icon-green me-2"
                            onClick={() => this.updateStatusBatch(d)}
                          >
                            <span className="material-icons visibility">
                              done
                            </span>
                            Accept
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row justify-content-between top-content align-items-center">
              <Showing
                limit={this.props.pageInfo.limit}
                totalData={this.props.pageInfo.totalData}
                page={this.props.pageInfo.page}
                // limit={this.state.limit}
                // totalData={400}
                // page={this.state.page}
              />
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-auto pe-0">
                    {this.props.pageInfo.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        // pageCount={4}
                        pageCount={this.props.pageInfo.totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        activeClassName="active"
                        activeLinkClassName="active"
                        initialPage={this.props.pageInfo.page - 1}
                        disableInitialCallback={true}
                        onPageChange={(e) => this.getOrderbyPage(e)}
                      />
                    ) : (
                      ""
                    )}
                    {/* <ul className="pagination">
                      {this.props.pageInfo.totalPage > 1 ? (
                        >
                          <li className="page-item">
                            <button
                              disabled={this.props.pageInfo.page <= 1}
                              className="page-link"
                              onClick={() =>
                                this.getOrderbyPage(this.state.page - 1)
                              }
                            >
                              Previous
                            </button>
                          </li>
                          {this.page()}
                          <li className="page-item">
                            <button
                              disabled={
                                this.props.pageInfo.page ===
                                this.props.pageInfo.totalPage
                              }
                              className="page-link"
                              onClick={() =>
                                this.getOrderbyPage(this.state.page + 1)
                              }
                            >
                              Next
                            </button>
                          </li>
                        </>
                      ) : (
                        <li> </li>
                      )}
                    </ul>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let incomingorder = [];
  var pageInfo = {};
  let error = null;
  var postResponse = null;
  var totalData = null;
  var update = false;
  var stok = 0;
  if (!state.order.isLoading) {
    if (state.order.data !== null) {
      if (state.order.data.status) {
        pageInfo.limit = state.order.data.limit;
        pageInfo.totalPage = state.order.data.total_page;
        pageInfo.totalData = state.order.data.total_data;
        pageInfo.page = state.order.data.page;
        incomingorder = state.order.data.data;
        // for (let i = 0; i < state.order.data.data.length; i++) {
        //   if (state.order.data.data[i].status === "incoming") {
        //     incomingorder.push(state.order.data.data[i]);
        //   }
        // }
      }
    } else if (state.order.error !== null) {
      error = state.order.error.message;
    }
    if (
      state.order.postResponse !== null &&
      state.order.postResponse !== undefined
    ) {
      postResponse = state.order.postResponse;
    }
    if (
      state.order.checkNewData !== null &&
      state.order.checkNewData !== undefined
    ) {
      totalData = state.order.checkNewData.total_data;
    }
  }
  if (totalData !== null && pageInfo.totalData !== undefined) {
    if (totalData !== pageInfo.totalData) {
      update = true;
    } else {
      update = false;
    }
  }
  if (state.master.imei !== null && state.master.imei !== undefined) {
    stok = state.master.imei.length;
  }
  return {
    pageInfo: pageInfo,
    incomingOrder: incomingorder,
    token: state.auth.dataUser.data.token,
    error: error,
    isLoading: state.order.isLoading,
    postResponse: postResponse,
    totalData: totalData,
    update: update,
    stok: stok,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (token, limit, page) => dispatch(getOrder(token, limit, page)),
    putStatusOrder: (id, token) => dispatch(putStatusOrder(id, token)),
    putStatusAllOrder: (ids, token) => dispatch(putStatusAllOrder(ids, token)),
    checkNewData: (token, url) => dispatch(checkNewData(token, url)),
    getImei: (token) => dispatch(getImei(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Incoming);
