import {
  GET_IMEI_SUCCESS,
  POST_MASTER_FAILED,
  POST_MASTER_REQUEST,
  POST_MASTER_SUCCESS,
  GET_MASTER_FAILED,
  GET_MASTER_REQUEST,
  GET_MASTER_SUCCESS,
} from "./type";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
  imei: null,
  postResponse: null,
};
export const master = (state = initialState, action) => {
  switch (action.type) {
    case POST_MASTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postResponse: action.payload,
        error: null,
      };
    case POST_MASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_MASTER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case GET_MASTER_SUCCESS:
      return {
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case GET_MASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MASTER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_IMEI_SUCCESS:
      return {
        imei: action.payload,
        error: null,
      };
    default:
      return state;
  }
};
