import { LOGIN_FAILED, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "./type";

const initialState = {
  isLogin: false,
  isLoading: false,
  dataUser: null,
  error: "",
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        isLoading: false,
        isLogin: true,
        dataUser: action.payload,
        error: "",
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        isLogin: false,
        isLoading: false,
        dataUser: null,
        error: "",
      };
    default:
      return state;
  }
};
export default auth;
