import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Breadcrumb } from "../../layouts/Breadcrumb";
import Header from "../../layouts/Header";
import Showing from "../../layouts/Showing";
import ExcelIcon from "../../assets/svg/excel.svg";
import { ModalDetailMaster, ModalImport } from "../../layouts/Modal";
import { getMaster, postMaster } from "../../store/master/actions";
import { LoadingSend } from "../../layouts/Loading";
import swal from "sweetalert";
import Search from "../../layouts/Search";

class Master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailData: [],
      sQuery: "",
    };
  }
  showModal = (data) => {
    this.setState({
      detailData: data,
    });
  };
  componentDidMount() {
    this.props.getMaster({
      token: this.props.token,
      search: this.state.sQuery,
    });
  }
  getOrderbyPage(currentPage) {
    this.props.getMaster({
      token: this.props.token,
      page: currentPage.selected + 1,
      limit: this.props.pageInfo.limit,
      search: this.state.sQuery,
    });
  }
  getOrderbyLimit = (limit) => {
    this.props.getMaster({
      token: this.props.token,
      limit: limit.target.value,
      // status: "incoming",
      search: this.state.sQuery,
    });
  };
  status = (status) => {
    switch (status) {
      case "process":
        status = status.toUpperCase();
        break;
      default:
        status = status.toUpperCase();
        break;
    }
    return <p className={status}>{status}</p>;
  };
  render() {
    if (this.props.error !== null && this.props.error !== undefined) {
      swal("Something Wrong!", `${this.props.error}`, "error").then((value) =>
        this.props.getMaster({
          token: this.props.token,
          search: this.state.sQuery,
        })
      );
    }
    if (
      this.props.postResponse !== null &&
      this.props.postResponse !== undefined
    ) {
      if (this.props.postResponse.status) {
        swal(
          "Great!",
          `${
            this.props.postResponse.duplicate !== undefined
              ? `duplicate : ${this.props.postResponse.duplicate.length} data`
              : ``
          }`,
          "success"
        ).then((value) =>
          this.props.getMaster({
            token: this.props.token,
            search: this.state.sQuery,
          })
        );
      } else {
        swal(
          "Something Wrong!",
          `${this.props.postResponse.message}`,
          "error"
        ).then((value) =>
          this.props.getMaster({
            token: this.props.token,
            search: this.state.sQuery,
          })
        );
      }
    }
    return (
      <>
        <ModalImport
          header={(fileMaster) =>
            this.props.postMaster({
              fileMaster: fileMaster,
              token: this.props.token,
            })
          }
        />
        {this.props.postLoading ? <LoadingSend /> : ""}
        <ModalDetailMaster data={this.state.detailData} />
        <Header title="Master Data" />
        <div className="base">
          <Breadcrumb subtitle="Master Data" />
          <div className="table-responsive base-table">
            <div className="row mb-4 top-content">
              <div className="col-md-6 col-6">
                <div className="col-md-6 col-8">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-3">show</div>
                    <div className="col-md-5 col-5">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => this.getOrderbyLimit(e)}
                      >
                        <option defaultValue value="10">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-4">entires</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pe-0 col-6">
                <div className="row justify-content-end">
                  <div className="col-md-6 col-6 align-self-end">
                    <div className="row justify-content-end align-items-center">
                      <div className="col-md-10 col-10 justify-content-end d-flex">
                        <button
                          className="btn-import w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#modalImport"
                        >
                          <div className="row align-items-center">
                            <div className="excel-icon col-auto pe-0">
                              <img
                                src={ExcelIcon}
                                alt="excel"
                                width="26px"
                                height="22px"
                              />
                            </div>
                            <p className="link-title col-auto pe-0">Import</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <Search
                    onSearch={(e) => {
                      this.setState({ sQuery: e });
                      this.props.getMaster({
                        token: this.props.token,
                        search: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">ESN</th>
                  <th scope="col">MSISDN</th>
                  <th scope="col">SSID</th>
                  <th scope="col">Device Model</th>
                  <th scope="col">IMEI</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.dataMaster.length >= 1 ? (
                  this.props.dataMaster.map((d, index) => (
                    <tr key={index}>
                      <td>
                        {this.props.pageInfo.page > 1
                          ? (this.props.pageInfo.page - 1) *
                              this.props.pageInfo.limit +
                            (index + 1)
                          : index + 1}
                      </td>
                      <td>{d.esn}</td>
                      <td>{d.msisdn}</td>
                      {/* <td>{d.items[0].name}</td> */}
                      <td>{d.ssid}</td>
                      <td>{d.device_model}</td>
                      <td>{d.imei}</td>
                      <td>
                        <div className="row w-auto px-3 justify-content-center">
                          <div
                            className="col-auto bg-icon-blue mb-2 me-2"
                            onClick={() => this.showModal(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#detailMaster"
                          >
                            <span className="material-icons visibility">
                              visibility
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row justify-content-between top-content align-items-center">
              <Showing
                limit={this.props.pageInfo.limit}
                totalData={this.props.pageInfo.totalData}
                page={this.props.pageInfo.page}
              />
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-auto pe-0">
                    {this.props.pageInfo.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        // pageCount={4}
                        pageCount={this.props.pageInfo.totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        activeClassName="active"
                        activeLinkClassName="active"
                        disableInitialCallback={true}
                        initialPage={this.props.pageInfo.page - 1}
                        // initialPage={this.props.pageInfo.page - 1}
                        onPageChange={(e) => this.getOrderbyPage(e)}
                      />
                    ) : (
                      ""
                    )}
                    {/* <ul className="pagination">
                      <li className="page-item">
                        <Link className="page-link" to="/">
                          Previous
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link active" to="/">
                          1
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="/">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="/">
                          3
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="/">
                          Next
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let dataMaster = [];
  var pageInfo = {};
  var error = null;
  var postResponse = null;
  if (!state.master.isLoading) {
    if (state.master.data !== null && state.master.data !== undefined) {
      if (state.master.data.status) {
        pageInfo.limit = state.master.data.limit;
        pageInfo.totalPage = state.master.data.total_page;
        pageInfo.totalData = state.master.data.total_data;
        pageInfo.page = state.master.data.page;
        dataMaster = state.master.data.data;
        // dataMaster = state.master.data;
        // for (let i = 0; i < state.maste.data.data.length; i++) {
        //   if (state.maste.data.data[i].status === "incoming") {
        //     incomingmaste.push(state.maste.data.data[i]);
        //   }
        // }
      }
    }
    if (state.master.error !== null && state.master.error !== undefined) {
      error = state.master.error.message;
    }
    if (
      state.master.postResponse !== null &&
      state.master.postResponse !== undefined
    ) {
      postResponse = state.master.postResponse;
    }
  }
  return {
    pageInfo: pageInfo,
    dataMaster: dataMaster,
    token: state.auth.dataUser.data.token,
    postLoading: state.master.isLoading,
    postResponse: postResponse,
    error: error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMaster: (token) => dispatch(getMaster(token)),
    postMaster: (fileMaster, token) => dispatch(postMaster(fileMaster, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Master);
