import React, { Component, useRef, useState } from "react";
import { createRef } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { getImei } from "../store/master/actions";
import { putPackaging } from "../store/order/actions";

export const ModalDetail = ({ data }) => {
  const dateFormat = (date) => {
    var newDate = date.split(".");
    return newDate[0].replace("T", " ");
  };
  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row w-100 align-items-center gx-0">
              <div className="col-md-4"></div>
              <div className="col-md-4 justify-content-center d-flex">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Detail Order
                </h5>
              </div>
              <div className="col-md-4 justify-content-end d-flex">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div className="modal-body">
            {data.length === 0 ? (
              "No data to show"
            ) : (
              <div className="row">
                <div className="col-md-12 col-12 order-lg-0">
                  <p className="title">Delivery Order</p>
                  <div className="row">
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">MSISDN:</p>
                      <p className="content">
                        {data.items[0].msisdn === null
                          ? "-"
                          : data.items[0].msisdn}
                      </p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">MSISDN Type:</p>
                      <p className="content">
                        {data.items[0].swo === null ? "-" : data.items[0].swo}
                      </p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Document Type:</p>
                      <p className="content">{`DELIVERY ORDER`}</p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Document Number:</p>
                      <p className="content">{data.document_number}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Delivery Order Type:</p>
                      <p className="content">
                        {data.delivery.type === null ? "-" : data.delivery.type}
                      </p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Delivery Order Number:</p>
                      <p className="content">
                        {data.order_number === null ? "-" : data.order_number}
                      </p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Delivery Order Date:</p>
                      <p className="content">
                        {data.delivery.date === null
                          ? "-"
                          : dateFormat(data.delivery.date)}
                      </p>
                    </div>
                    <div className="col-md-3 col-3  ">
                      <p className="subtitle">Notes:</p>
                      <p className="content">
                        {data.notes === null ? "-" : data.notes}
                      </p>
                    </div>
                  </div>
                  {/* <div className=" ">
                <p className="subtitle">Notes:</p>
                <p className="content">{data.notes}</p>
              </div> */}
                  {/* //item */}
                  <hr />
                  <p className="title">Item</p>
                  {data.items.map((d, i) => (
                    <div key={i}>
                      <p className="items-title">Item {i + 1}</p>
                      <div className="row">
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">SKU:</p>
                          <p className="content">
                            {d.sku === null ? "-" : d.sku}
                          </p>
                        </div>
                        <div className="col-md-1 col-1  ">
                          <p className="subtitle">Quantity:</p>
                          <p className="content">
                            {d.qty === null ? "-" : d.qty}
                          </p>
                        </div>
                        <div className="col-md-1 col-1  ">
                          <p className="subtitle">Batch:</p>
                          <p className="content">
                            {" "}
                            {d.batch === null ? "-" : d.batch}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">IMEI:</p>
                          <p className="content">
                            {" "}
                            {d.imei === null ? "-" : d.imei}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Barcode:</p>
                          <p className="content">
                            {" "}
                            {d.barcode === null ? "-" : d.barcode}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Condition:</p>
                          <p className="content">
                            {" "}
                            {d.condition === null ? "-" : d.condition}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Shipment Label:</p>
                          <p className="content">
                            {" "}
                            {d.shipment_label === null ? "-" : d.shipment_label}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <hr />
                  <p className="title">Receiver</p>
                  <div className="row">
                    <div className="col-md-2 col-2  ">
                      <p className="subtitle">Name:</p>
                      <p className="content">
                        {data.receiver.name === null ? "-" : data.receiver.name}
                      </p>
                    </div>
                    <div className="col-md-2 col-2  ">
                      <p className="subtitle">Phone Number:</p>
                      <p className="content">
                        {data.receiver.phone === null
                          ? "-"
                          : data.receiver.phone}
                      </p>
                    </div>
                    <div className="col-md-2 col-2  ">
                      <p className="subtitle">Postal Code:</p>
                      <p className="content">
                        {data.receiver.postal_code === null
                          ? "-"
                          : data.receiver.postal_code}
                      </p>
                    </div>
                    <div className="col-md-6 col-6  ">
                      <p className="subtitle">Destination:</p>
                      <p className="content">{data.receiver.destination}</p>
                    </div>
                  </div>
                  <hr />
                  <p className="title">Estimation</p>
                  <div className="row">
                    <div className="col-md-3 col-6  ">
                      <p className="subtitle">Estimation Send Date:</p>
                      <p className="content">
                        {data.estimation_send_date === null
                          ? "-"
                          : dateFormat(data.estimation_send_date)}
                      </p>
                    </div>
                  </div>
                  {/* courier */}
                  <hr />
                  {data.courier.name === null ? (
                    ""
                  ) : (
                    <>
                      <p className="title">Courier</p>
                      <div className="row">
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Name:</p>
                          <p className="content">
                            {data.courier.name === null
                              ? "-"
                              : data.courier.name}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Driver:</p>
                          <p className="content">
                            {data.courier.driver === null
                              ? "-"
                              : data.courier.driver}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Vehicle:</p>
                          <p className="content">
                            {data.courier.vehicle === null
                              ? "-"
                              : data.courier.vehicle}
                          </p>
                        </div>
                        <div className="col-md-2 col-2  ">
                          <p className="subtitle">Police Number:</p>
                          <p className="content">
                            {data.courier.name === null
                              ? "-"
                              : data.courier.police_no}
                          </p>
                        </div>
                        <div className="col-md-4 col-4">
                          <p className="subtitle">Driver Photo:</p>
                          <img
                            src={data.courier.driver_photo}
                            alt="Driver"
                            className="img-responsive"
                            height="196px"
                            width="196px"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

class ModalFormPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFromIMEI: [],
      editFromref: createRef(null),
      ship: null,
      update: false,
    };
  }
  componentDidMount() {
    //get imei in here
    this.props.getImei({ token: this.props.token });
  }
  handleClose = () => {
    document.getElementById("editpac").reset();
    this.setState({ update: false });
    var imei = [];
    for (let i = 0; i < this.props.data.items.length; i++) {
      imei[i] = document.getElementById(`imei${i}`);
      imei[i].value = null;
    }

    // var batch = [];
    // var imei = [];
    // var msisdn = [];
    // var sl = [];
    // // var barcode = [];
    // var swo = [];
    // for (let i = 0; i < this.props.data.items.length; i++) {
    //   batch[i] = document.getElementById(`batch${i}`);
    //   imei[i] = document.getElementById(`imei${i}`);
    //   msisdn[i] = document.getElementById(`msisdn${i}`);
    //   sl[i] = document.getElementById(`shipment${i}`);
    //   // barcode[i] = document.getElementById(`barcode${i}`);
    //   swo[i] = document.getElementById(`swo${i}`);
    //   imei[i].value = "";
    //   batch[i].value = "";
    //   msisdn[i].value = "";
    //   sl[i].value = "";
    //   // barcode[i].value = "";
    //   swo[i].value = "";
    // }
  };
  handleSubmit = (value) => {
    value.preventDefault();
    var sku = [];
    var qty = [];
    var batch = [];
    var imei = [];
    var msisdn = [];
    var swo = [];
    var shipment_label = [];
    var condition = [];
    var barcode = [];
    var form = [];
    var idImei = [];
    // var idEdit = "";
    for (let i = 0; i < this.props.data.items.length; i++) {
      sku[i] = document.getElementById(`sku${i}`);
      qty[i] = document.getElementById(`qty${i}`);
      batch[i] = document.getElementById(`batch${i}`);
      imei[i] = document.getElementById(`imei${i}`);
      msisdn[i] = document.getElementById(`msisdn${i}`);
      swo[i] = document.getElementById(`swo${i}`);
      shipment_label[i] = document.getElementById(`shipment${i}`);
      condition[i] = document.getElementById(`condition${i}`);
      barcode[i] = document.getElementById(`barcode${i}`);
      idImei[i] = document.getElementById(`idImei${i}`);
      // idEdit = this.props.data.items[i]._id;
      form[i] = {
        id: idImei[i].value,
        sku: sku[i].value,
        qty: qty[i].value,
        batch: batch[i].value,
        msisdn: msisdn[i].value,
        swo: swo[i].value,
        shipment_label: shipment_label[i].value,
        condition: condition[i].value,
        barcode: barcode[i].value,
        imei: imei[i].value,
      };
      // if (form[i].msisdn !== undefined) {
      //   this.setState({ update: true });
      // }
    }

    if (this.state.update) {
      this.props.putPackaging({
        data: form,
        token: this.props.token,
        id: this.props.data._id,
      });
      this.props.getImei({ token: this.props.token });
      this.state.editFromref.current.reset();
      this.handleClose();
    }
  };
  handleShipment = (e) => {
    this.setState({
      ship: e.target.value,
    });
  };
  handleImeiChange = (e, ke) => {
    // var batch = [];
    var imei = [];
    var msisdn = [];
    var idImei = [];
    // var sl = [];
    // var barcode = [];
    // var swo = [];
    for (let i = 0; i < this.props.data.items.length; i++) {
      if (i === ke) {
        // batch[i] = document.getElementById(`batch${i}`);
        // batch[i].value = e.shipmentLabel;
        msisdn[i] = document.getElementById(`msisdn${i}`);
        msisdn[i].value = e.msisdn;
        idImei[i] = document.getElementById(`idImei${i}`);
        idImei[i].value = e.id;
        // sl[i] = document.getElementById(`shipment${i}`);
        // sl[i].value = e.shipmentLabel;
        imei[i] = document.getElementById(`imei${i}`);
        imei[i].value = e.label;
        // barcode[i] = document.getElementById(`barcode${i}`);
        // barcode[i].value = e.shipmentLabel;
        // swo[i] = document.getElementById(`swo${i}`);
        // swo[i].value = e.shipmentLabel;
      }
    }
    this.setState({ update: true });
  };
  colourStyles = {
    option: (styles) => ({
      ...styles,
      backgroundColor: "#FFF",
      color: "#1B252F",
    }),
    control: (styles) => ({
      ...styles,
      height: "50px",
    }),
    singleValue: (styles) => ({
      ...styles,
      opacity: !this.state.update ? 0 : 1,
    }),
  };
  render() {
    return (
      <div
        className="modal fade"
        id="modalForm"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog package modal-dialog-scrollablex">
          <div className="modal-content">
            <div className="modal-header">
              <div className="row w-100 align-items-center gx-0">
                <div className="col-md-4 col-4">
                  <span className="material-icons">print</span>
                </div>
                <div className="col-md-4 col-4 justify-content-center d-flex">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Detail Order
                  </h5>
                </div>
                <div className="col-md-4 col-4 justify-content-end d-flex">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.handleClose()}
                  ></button>
                </div>
              </div>
            </div>
            <form
              id="editpac"
              onSubmit={(e) => this.handleSubmit(e)}
              ref={this.state.editFromref}
            >
              <div className="modal-body">
                <p className="subtitle mb-3">Name of Item</p>
                {this.props.data.length <= 0 ? (
                  "No data to show"
                ) : (
                  <div className="table-responsiveb">
                    {this.props.data.items.map((d, i) => (
                      <div className="row form-item" key={i}>
                        <div className="form-group satu">
                          <label htmlFor="subject">SKU</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`sku${i}`}
                            name={`sku${i}`}
                            placeholder={d.sku}
                            value={d.sku}
                            readOnly
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id={`idImei${i}`}
                          name={`idImei${i}`}
                          hidden
                        />
                        <div className="form-group dua">
                          <label htmlFor="subject">Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`qty${i}`}
                            name={`qty${i}`}
                            placeholder={d.qty}
                            value={d.qty}
                            readOnly
                          />
                        </div>
                        <div className="form-group col-2">
                          <label htmlFor="subject">IMEI</label>
                          <Select
                            styles={this.colourStyles}
                            options={this.props.imei}
                            // label="Select IMEI"
                            isSearchable={true}
                            id={`imei${i}`}
                            name={`imei${i}`}
                            // value={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "null"
                            //     : this.state.dataFromIMEI[i].label
                            // }
                            onChange={(e) => this.handleImeiChange(e, i)}
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            id="imei"
                            name="ime"
                            placeholder="IMEI"
                            required
                          /> */}
                        </div>
                        <div className="form-group tiga">
                          <label htmlFor="subject">MSISDN</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`msisdn${i}`}
                            name={`msisdn${i}`}
                            // placeholder={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            // value={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            required
                            readOnly
                          />
                        </div>
                        <div className="form-group tiga">
                          <label htmlFor="subject">Batch</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`batch${i}`}
                            name={`batch${i}`}
                            placeholder="Batch"
                            // placeholder={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            // value={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            // required
                            // readOnly
                          />
                        </div>

                        <div className="form-group tiga">
                          <label htmlFor="subject">SWO</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`swo${i}`}
                            name={`swo${i}`}
                            placeholder="SWO"
                            // placeholder={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            // value={
                            //   this.state.dataFromIMEI[i] === undefined
                            //     ? "Pilih IMEI"
                            //     : this.state.dataFromIMEI[i].shipmentLabel
                            // }
                            // required
                            // readOnly
                          />
                        </div>
                        <div className="form-group tiga">
                          <label htmlFor="subject">Shipment Label</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`shipment${i}`}
                            name={`shipment${i}`}
                            onChange={(e) => this.handleShipment(e)}
                            placeholder="Shipment Label"
                            required
                            // readOnly
                          />
                        </div>
                        <div className="form-group tiga">
                          <label htmlFor="subject">Barcode</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`barcode${i}`}
                            name={`barcode${i}`}
                            placeholder="Barcode"
                            // placeholder={d.sku}
                            value={d.sku}
                            required
                            readOnly
                          />
                        </div>
                        <div className="form-group tiga">
                          <label htmlFor="subject">Condition</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`condition${i}`}
                            name={`condition${i}`}
                            placeholder="Condition"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-dark "
                  data-bs-dismiss={
                    this.state.update && this.state.ship !== null
                      ? `modal`
                      : `no`
                  }
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  let imei = [];
  if (state.master.imei !== null) {
    if (state.master.imei) {
      imei = state.master.imei;
      // for (let i = 0; i < state.order.data.data.length; i++) {
      //   if (state.order.data.data[i].status === "incoming") {
      //     incomingorder.push(state.order.data.data[i]);
      //   }
      // }
    }
  }
  return {
    imei: imei,
    token: state.auth.dataUser.data.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getImei: (token) => dispatch(getImei(token)),
    putPackaging: (data) => dispatch(putPackaging(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalFormPackage);

export const ModalImport = ({ header }) => {
  const [fileMaster, setstate] = useState(null);
  const refForm = useRef(null);

  const handleChange = (event) => {
    // event.preventDefault();
    setstate(event.target.files[0]);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (fileMaster !== null) {
      header(fileMaster);
      setstate(null);
      refForm.current.reset();
    }
  };
  return (
    <div
      className="modal fade"
      id="modalImport"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ">
        <div className="modal-content import">
          <div className="modal-header">
            <div className="row w-100 align-items-center gx-0">
              <div className="col-md-2 col-2">
                {/* <span className="material-icons">print</span> */}
              </div>
              <div className="col-md-8 col-8 justify-content-center d-flex">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Upload File Excel
                </h5>
              </div>
              <div className="col-md-2 col-2 justify-content-end d-flex">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)} ref={refForm}>
            <div className="modal-body">
              <div className="table-responsive">
                <div className="row">
                  <div className="form-group mb-0">
                    <label htmlFor="subject">Upload File</label>
                    <input
                      type="file"
                      className="form-control2"
                      accept=".csv"
                      id="master"
                      name="master"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                    <p className="label">*File must be .csv</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                data-bs-dismiss={`${fileMaster === null ? `` : `modal`}`}
                type="submit"
                className="btn btn-dark "
              >
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const ModalDetailMaster = ({ data }) => {
  return (
    <div
      className="modal fade"
      id="detailMaster"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row w-100 align-items-center gx-0">
              <div className="col-md-4"></div>
              <div className="col-md-4 justify-content-center d-flex">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Detail Data
                </h5>
              </div>
              <div className="col-md-4 justify-content-end d-flex">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div className="modal-body">
            {data.length === 0 ? (
              "No data to show"
            ) : (
              <div className="row">
                <div className="col-md-12 col-12 order-lg-0">
                  <div className="row">
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">ESN:</p>
                      <p className="content">
                        {data.esn === null ? "-" : data.esn}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Password Router:</p>
                      <p className="content">
                        {data.password_router === null
                          ? "-"
                          : data.password_router}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Password Admin Web:</p>
                      <p className="content">
                        {data.password_admin_web === null
                          ? "_"
                          : data.password_admin_web}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Device Type:</p>
                      <p className="content">
                        {data.device_type === null ? "-" : data.device_type}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">MSISDN:</p>
                      <p className="content">
                        {data.msisdn === null ? "-" : data.msisdn}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Guest SSID:</p>
                      <p className="content">
                        {data.guest_ssid === null ? "-" : data.guest_ssid}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">IMEI:</p>
                      <p className="content">
                        {data.imei === null ? "-" : data.imei}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Purchase Type:</p>
                      <p className="content">
                        {data.purchase_type === null ? "-" : data.purchase_type}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">SSID:</p>
                      <p className="content">
                        {data.ssid === null ? "-" : data.ssid}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Password Guest:</p>
                      <p className="content">
                        {data.password_guest === null
                          ? "-"
                          : data.password_guest}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Device Model:</p>
                      <p className="content">
                        {data.device_model === null ? "-" : data.device_model}
                      </p>
                    </div>
                    <div className="col-md-3 col-3 mb-3">
                      <p className="subtitle">Outlet Type:</p>
                      <p className="content">
                        {/* {data.notes === null ? "-" : "data.notes"} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalFilter = ({ Filter, reset }) => {
  const [fileMaster, setstate] = useState({
    startDate: null,
    endDate: null,
    courier: "",
    filter: true,
  });
  const [selectedName, setSelect] = useState(null);
  let refForm = useRef(null);
  const [startDate, setDate] = useState();

  const handleChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    setstate({
      ...fileMaster,
      [name]: value,
      filter: true,
    });
    if (name === "startDate") {
      setDate(value);
    }
  };
  const handleChange2 = (event) => {
    // event.preventDefault();
    const { value } = event.target;

    if (value === "lainnya") {
      setSelect(value);
    } else {
      setSelect("reset");
      setstate({
        ...fileMaster,
        courier: value,
        filter: true,
      });
    }
  };
  function handleSubmit(event) {
    event.preventDefault();
    if (fileMaster.startDate !== null && fileMaster.endDate !== null) {
      Filter(fileMaster);
      // setstate(null);
      // refForm.current.reset();
    }
  }
  function handleClose(event) {
    refForm.reset();
    document.getElementById("startDate").value = null;
    document.getElementById("endDate").value = null;
    // document.getElementById("selected_name").value = "";
    setSelect("reset");
    setstate({
      startDate: null,
      endDate: null,
      courier: "",
      filter: false,
    });
    reset();
  }
  const myDate = () => {
    var today = new Date();
    // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate
    var date = today.toISOString().split("T");
    return date[0].toString();
  };
  
  return (
    <div
      className="modal fade"
      id="modalFilter"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ">
        <div className="modal-content import">
          <div className="modal-header">
            <div className="row w-100 align-items-center gx-0">
              <div className="col-md-2 col-2">
                {/* <span className="material-icons">print</span> */}
              </div>
              <div className="col-md-8 col-8 justify-content-center d-flex">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Filtering Data
                </h5>
              </div>
              <div className="col-md-2 col-2 justify-content-end d-flex">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <form
            id="formFilter"
            onSubmit={(e) => handleSubmit(e)}
            ref={(el) => (refForm = el)}
          >
            <div className="modal-body">
              <div className="table-responsive">
                <div className="row mx-0">
                  <div className="col-12 mb-3">
                    <div className="form-group mb-0">
                      <label htmlFor="subject">Deliverer Name</label>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        onChange={(e) => handleChange2(e)}
                        name="selected_name"
                      >
                        <option defaultValue value="">
                          Select Deliverer Name
                        </option>
                        <option value="Gojek Instant">Gojek Instant</option>
                        <option value="Grab Instant">Grab Instant</option>
                        <option value="Anteraja">Anteraja</option>
                        <option value="J&T">J&T</option>
                        <option value="JNE">JNE</option>
                        <option value="SiCepat">SiCepat</option>
                        <option value="NinjaExpress">NinjaExpress</option>
                        <option value="ShopeeExpress">ShopeeExpress</option>
                        <option value="Kantor POS">Kantor POS</option>
                        <option value="ID Express">ID Express</option>
                        <option value="Tiki">Tiki</option>
                        <option value="lainnya">+ Lainnya</option>
                      </select>
                      {selectedName === "lainnya" &&
                      selectedName !== "reset" ? (
                        <input
                          type="text"
                          className="form-control2"
                          id="courier"
                          name="courier"
                          onChange={(e) => handleChange(e)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-0">
                      <label htmlFor="subject">Start Date</label>
                      <input
                        type="date"
                        className="form-control2"
                        id="startDate"
                        name="startDate"
                        onChange={(e) => handleChange(e)}
                        required
                        max={myDate()}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-0">
                      <label htmlFor="subject">End Date</label>
                      <input
                        type="date"
                        className="form-control2"
                        id="endDate"
                        name="endDate"
                        onChange={(e) => handleChange(e)}
                        max={myDate()}
                        min={startDate}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer mx-2">
              <button
                className="btn btn-dark "
                data-bs-dismiss="modal"
                onClick={() => handleClose()}
              >
                Reset
              </button>
              <button
                data-bs-dismiss={`${
                  fileMaster.startDate === null ||
                  fileMaster.endDate === null ||
                  fileMaster.startDate === "" ||
                  fileMaster.endDate === ""
                    ? ``
                    : `modal`
                }`}
                type="submit"
                className="btn btn-dark "
              >
                Terapkan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
