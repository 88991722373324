import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Breadcrumb } from "../../layouts/Breadcrumb";
import Header from "../../layouts/Header";
import { LoadData } from "../../layouts/Loading";
import ModalAddMember from "../../layouts/ModalAddMember";
import Search from "../../layouts/Search";
import Showing from "../../layouts/Showing";
import { getMember } from "../../store/member/actions";

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sQuery: "",
    };
  }
  componentDidMount() {
    this.props.getMember({ token: this.props.token });
  }
  getOrderbyPage = (currentPage) => {
    this.props.getMember({
      token: this.props.token,
      page: currentPage.selected + 1,
      limit: this.props.pageInfo.limit,
      search: this.state.sQuery,
    });
  };
  getOrderbyLimit = (limit) => {
    this.props.getMember({
      token: this.props.token,
      limit: limit.target.value,
      // status: "incoming",
      search: this.state.sQuery,
    });
  };
  render() {
    if (this.props.error !== "" && !this.props.isLoading) {
      swal("Something Wrong!", `${this.props.error}`, "error", {
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then((value) =>
        this.props.getMember({
          token: this.props.token,
          search: this.state.sQuery,
        })
      );
    }
    if (
      this.props.postResponse !== null &&
      this.props.postResponse !== undefined
    ) {
      if (this.props.postResponse.status) {
        swal("Great!", `${this.props.postResponse.message}`, "success").then(
          (value) =>
            this.props.getMember({
              token: this.props.token,
              search: this.state.sQuery,
            })
        );
      } else {
        swal(
          "Something Wrong!",
          `${this.props.postResponse.message}`,
          "error"
        ).then((value) =>
          this.props.getMember({
            token: this.props.token,
            search: this.state.sQuery,
          })
        );
      }
    }
    return (
      <>
        <ModalAddMember />
        <Header title="Member" />
        <div className="base">
          <Breadcrumb subtitle="Member" />
          <div className="table-responsive base-table">
            <div className="row mb-4 top-content">
              <div className="col-md-6 col-6">
                <div className="col-md-6 col-8">
                  <div className="row align-items-center">
                    <div className="col-md-3 col-3">show</div>
                    <div className="col-md-5 col-5">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => this.getOrderbyLimit(e)}
                      >
                        <option defaultValue value="10">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-4">entires</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pe-0 col-6">
                <div className="row justify-content-end">
                  <div className="col-md-6 col-6 align-self-end">
                    <div className="row justify-content-end align-items-center">
                      {
                        <div className="col-auto">
                          <button
                            className="btn-outline-grey btn"
                            // onClick={() => {
                            //   this.onPrint(this.props.incomingOrder);
                            // }}
                            data-bs-toggle="modal"
                            data-bs-target="#modaladdmember"
                          >
                            <div className="col d-flex">
                              <span className="material-icons visibility me-2">
                                person_add
                              </span>
                              <div className="all-text">Add Member</div>
                            </div>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                  <Search
                    onSearch={(e) => {
                      this.setState({ sQuery: e });
                      this.props.getMember({
                        token: this.props.token,
                        search: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Level</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {this.props.isLoading ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      <LoadData />
                    </td>
                  </tr>
                ) : this.props.member.length >= 1 ? (
                  this.props.member.map((d, index) => (
                    <tr key={index}>
                      <td>
                        {this.props.pageInfo.page > 1
                          ? (this.props.pageInfo.page - 1) * this.props.pageInfo.limit + (index + 1)
                          : index + 1}
                      </td>
                      <td>{d.name}</td>
                      <td>{d.email}</td>
                      {/* <td>{d.items[0].name}</td> */}
                      <td>{d.level}</td>

                      {/* <td>
                        <div className="row w-auto px-3 justify-content-center">
                          <div
                            className="col-auto bg-icon-blue mb-2 me-2"
                            // onClick={() => this.showModal(d)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          >
                            <span className="material-icons visibility">
                              visibility
                            </span>
                          </div>
                          <div
                            className="col-auto bg-icon-green me-2"
                            // onClick={() => this.updateStatusBatch(d)}
                          >
                            <span className="material-icons visibility">
                              done
                            </span>
                            Accept
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row justify-content-between top-content align-items-center">
              <Showing
                limit={this.props.pageInfo.limit}
                totalData={this.props.pageInfo.totalData}
                page={this.props.pageInfo.page}
                // limit={this.state.limit}
                // totalData={400}
                // page={this.state.page}
              />
              <div className="col-md-6 col-6 pe-0">
                <div className="row justify-content-end">
                  <div className="col-auto pe-0">
                    {this.props.pageInfo.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        // pageCount={4}
                        pageCount={this.props.pageInfo.totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageLinkClassName="page-link"
                        pageClassName="page-item"
                        activeClassName="active"
                        activeLinkClassName="active"
                        disableInitialCallback={true}
                        initialPage={this.props.pageInfo.page - 1}
                        onPageChange={(e) => this.getOrderbyPage(e)}
                      />
                    ) : (
                      ""
                    )}
                    {/* <ul className="pagination">
                      {this.props.pageInfo.totalPage > 1 ? (
                        >
                          <li className="page-item">
                            <button
                              disabled={this.props.pageInfo.page <= 1}
                              className="page-link"
                              onClick={() =>
                                this.getOrderbyPage(this.state.page - 1)
                              }
                            >
                              Previous
                            </button>
                          </li>
                          {this.page()}
                          <li className="page-item">
                            <button
                              disabled={
                                this.props.pageInfo.page ===
                                this.props.pageInfo.totalPage
                              }
                              className="page-link"
                              onClick={() =>
                                this.getOrderbyPage(this.state.page + 1)
                              }
                            >
                              Next
                            </button>
                          </li>
                        </>
                      ) : (
                        <li> </li>
                      )}
                    </ul>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let member = [];
  var pageInfo = {};
  var postResponse = null;
  var error = "";
  if (!state.member.isLoading) {
    if (state.member.error !== "") {
      error = state.member.error.message;
    }
    if (state.member.data !== null) {
      if (state.member.data.status) {
        pageInfo.limit = state.member.data.limit;
        pageInfo.totalPage = state.member.data.total_page;
        pageInfo.totalData = state.member.data.total_data;
        pageInfo.page = state.member.data.page;
        member = state.member.data.data;
      }
    }
    if (
      state.member.postResponse !== null &&
      state.member.postResponse !== undefined
    ) {
      postResponse = state.member.postResponse;
    }
  }
  return {
    token: state.auth.dataUser.data.token,
    error: error,
    member: member,
    pageInfo: pageInfo,
    isLoading: state.member.isLoading,
    postResponse: postResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMember: (token) => dispatch(getMember(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Member);
