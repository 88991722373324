import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumb = ({ subtitle }) => {
  return (
    <nav aria-label="breadcrumb" className="overflow-auto">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Order</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {subtitle}
        </li>
      </ol>
    </nav>
  );
};
