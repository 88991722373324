import React, { Component } from "react";
var Barcode = require("react-barcode");

const anteraja =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/anteraja.png?alt=media&token=707a86ec-d0cc-4951-bd2e-0b496ab68728";
const gosend =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/gosend.png?alt=media&token=739ffe06-6106-4019-9d56-c7ed1f82b44d";
const grab =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/grabexpress.png?alt=media&token=59996123-d76d-4078-90ad-372af4f7968e";
const idexpress =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/idexpress.png?alt=media&token=4355eb5e-2cee-41c9-89c6-c072ea9cefaa";
const jandt =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/j%26t.png?alt=media&token=1f53289a-eec1-4622-8a00-7f23642ce921";
const jne =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/jne.png?alt=media&token=43caab63-2fce-4bae-9848-b99e20c542ed";
const paxel =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/paxel.png?alt=media&token=bdc2a9f3-65fc-4487-8e44-2d01329b786f";
const pos =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/pos.png?alt=media&token=da93c00f-ac18-497e-9bf7-4f09a06a6d6f";
const shopee =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/shopeeexpress.png?alt=media&token=b5d28d52-546c-46b0-9a05-770e57621a34";
const sicepat =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/sicepat.png?alt=media&token=b3ac8793-5280-41a1-8524-e8f2190d9900";
const tiki =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/tiki.png?alt=media&token=e190770a-3558-4861-97b6-e17ac0187775";
const def =
  "https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/default.png?alt=media&token=7ab8fee5-3eb6-4c5c-b86d-0eb1287c4b87";

export default class Print extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     data: null,
  //   };
  // }
  //   componentDidMount() {
  //     this.setState({
  //       data: this.props.location.state,
  //     });
  //     wind
  //   }
  selectLogoExpedisi = (type = "PAXEL") => {
    if (type.includes("PAXEL")) {
      return paxel;
    } else if (type.includes("GRAB")) {
      return grab;
    } else if (type.includes("JNE")) {
      return jne;
    } else if (type.includes("ANTERAJA")) {
      return anteraja;
    } else if (type.includes("GOSEND")) {
      return gosend;
    } else if (type.includes("IDEXPRESS")) {
      return idexpress;
    } else if (type.includes("J&T")) {
      return jandt;
    } else if (type.includes("POS")) {
      return pos;
    } else if (type.includes("SHOPEE")) {
      return shopee;
    } else if (type.includes("SICEPAT")) {
      return sicepat;
    } else if (type.includes("TIKI")) {
      return tiki;
    } else {
      return def;
    }
  };
  render() {
    const data = this.props.data;
    return (
      <div>
        {data !== undefined && data !== null ? (
          data.map((data, i) => (
            <div
              key={i}
              className="vw-100 vh-10s0 mb-4 mt-4 d-flex justify-content-center align-items-center"
            >
              <div className="print-layouts">
                {data === undefined ? (
                  "No data to print"
                ) : (
                  <div>
                    <div className="print-header text-center">
                      <div className="row w-100 justify-content-center align-items-center">
                        <div className="col-4">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/advan-f9e1d.appspot.com/o/telkomsel.png?alt=media&token=49cbe81b-c129-422b-916a-fb5fc0cdddab"
                            alt="load"
                            width="208px"
                            height="77px"
                          />
                        </div>
                        <div className="col-4 text fs-4">
                          {data.delivery.type}
                        </div>
                        <div className="col-4">
                          <img
                            src={this.selectLogoExpedisi(data.delivery.type)}
                            alt="load"
                            width="208px"
                            height="77px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="print-body">
                      <div className="msisdn-section">
                        No. Resi : {data?.do.awb}
                        {/* MSISDN: {data.items[0].msisdn} */}
                      </div>
                      <div className="barcode-section w-100 d-flex justify-content-center">
                        <Barcode
                          value={data?.do.awb}
                          displayValue={false}
                          height={180}
                          width={4}
                          format="CODE128B"
                        />
                        {/* Not Allowet Now */}
                      </div>
                    </div>
                    <div className="print-subbody">
                      <div className="row">
                        <div className="col-6">
                          <p>Penerima : {data.receiver.name}</p>
                          {/* <p>Kota Asal : Jakarta Utara</p> */}
                        </div>
                        <div className="col-6">
                          <p>Pengirim : My Orbit</p>
                          {/* <p>Kota Tujuan : Jakarta Selatan</p> */}
                        </div>
                        <div className="col-12">
                          <p>
                            <span>No. HP : </span>
                            {data.receiver.phone}
                          </p>
                          <p>
                            <span>Alamat : </span>
                            {data.receiver.destination}
                          </p>
                          <p>
                            <span>No. Pesanan : </span> {data.order_number}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="print-footer mt-2">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  maxWidth: `10px`,
                                }}
                                scope="col"
                              >
                                No
                              </th>
                              <th scope="col">SKU</th>
                              <th scope="col">QTY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.items.map((d, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    maxWidth: `10px`,
                                  }}
                                >
                                  {i + 1}
                                </td>
                                <td>{d.sku}</td>
                                <td>{d.qty}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>Print Wrong</div>
        )}
      </div>
    );
  }
}
