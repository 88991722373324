import { Route } from "react-router-dom";
import Sidebar from "./layouts/Sidebar";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/login/Login";
import Master from "./pages/master/Master";
import Member from "./pages/member/Member";
import Done from "./pages/orders/Done";
import Incoming from "./pages/orders/Incoming";
import Packaging from "./pages/orders/Packaging";
import Pickup from "./pages/orders/Pickup";
import ProtectRoute from "./store/ProtectRoute";

function App() {
  const pathname = window.location.pathname.split("/");
  return (
    <>
      <div className="advan">
        <div className="row mx-0">
          {pathname[1] === "login" ? (
            <ProtectRoute
              redirectPath=""
              path="/login"
              exact
              component={Login}
            />
          ) : pathname[1] === "" ||
            pathname[1] === "packaging" ||
            pathname[1] === "pickup" ||
            pathname[1] === "done" ||
            pathname[1] === "member" ||
            // pathname[1] === "report" ||
            pathname[1] === "masterdata" ? (
            <>
              <div className="col-auto px-0 side">
                <Sidebar />
              </div>
              <div className="col px-0">
                <ProtectRoute
                  redirectPath="login"
                  path="/"
                  exact
                  component={Incoming}
                />
                <ProtectRoute
                  redirectPath="login"
                  path="/packaging"
                  component={Packaging}
                />
                <ProtectRoute
                  redirectPath="login"
                  path="/pickup"
                  component={Pickup}
                />

                <ProtectRoute
                  redirectPath="login"
                  path="/done"
                  component={Done}
                />
                <ProtectRoute
                  redirectPath="login"
                  path="/masterdata"
                  component={Master}
                />
                 {/* <ProtectRoute
                  redirectPath="login"
                  path="/report"
                  component={Report}
                /> */}
                <ProtectRoute
                  redirectPath="login"
                  path="/member"
                  component={Member}
                />
              </div>
            </>
          ) : (
            <Route path="*" component={ErrorPage} />
          )}
        </div>
      </div>
    </>
  );
}

export default App;
