export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_FAILED = "GET_ORDER_FAILED";
export const PUT_STATUS_ORDER_SUCCESS = "PUT_STATUS_ORDER_SUCCESS";
export const PUT_STATUS_ORDER_REQUEST = "PUT_STATUS_ORDER_REQUEST";
export const PUT_STATUS_ORDER_FAILED = "PUT_STATUS_ORDER_FAILED";
export const PUT_PACKAGING_SUCCESS = "PUT_PACKAGING_SUCCESS";
export const PUT_PACKAGING_REQUEST = "PUT_PACKAGING_REQUEST";
export const PUT_PACKAGING_FAILED = "PUT_PACKAGING_FAILED";
export const PUT_STATUS_PACKAGING_SUCCESS = "PUT_STATUS_PACKAGING_SUCCESS";
export const PUT_STATUS_PACKAGING_REQUEST = "PUT_STATUS_PACKAGING_REQUEST";
export const PUT_STATUS_PACKAGING_FAILED = "PUT_STATUS_PACKAGING_FAILED";
export const PUT_PICKUP_SUCCESS = "PUT_PICKUP_SUCCESS";
export const PUT_PICKUP_REQUEST = "PUT_PICKUP_REQUEST";
export const PUT_PICKUP_FAILED = "PUT_PICKUP_FAILED";
export const PUT_STATUS_ALL_ORDER_SUCCESS = "PUT_STATUS_ALL_ORDER_SUCCESS";
export const PUT_STATUS_ALL_ORDER_REQUEST = "PUT_STATUS_ALL_ORDER_REQUEST";
export const PUT_STATUS_ALL_ORDER_FAILED = "PUT_STATUS_ALL_ORDER_FAILED";
export const CHECK_NEW_DATA = "CHECK_NEW_DATA";
