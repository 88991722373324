import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { logout } from "../store/auth/actions";
import Sidebar from "./Sidebar";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailData: [],
      menuShow: false,
    };
  }
  menuClick = () => {
    this.setState({
      menuShow: !this.state.menuShow,
    });
  };
  render() {
    const state = this.state;
    return (
      <header className="w-100 overflow-auto">
        {/* <p className="title">{this.props.title}</p> */}
        <div className="row w-100 mx-0">
          <div className="col-auto d-flex align-items-center menu">
            {state.menuShow ? (
              <span
                role="button"
                onClick={this.menuClick}
                className="material-icons"
              >
                close
              </span>
            ) : (
              <span
                role="button"
                onClick={this.menuClick}
                className="material-icons"
              >
                menu
              </span>
            )}
            <div className={`menu-show ${!state.menuShow ? `hides` : ``}`}>
              <Sidebar />
            </div>
          </div>
          <div className="col-auto">
            <p className="title">{this.props.title}</p>
          </div>
        </div>
        <div className="nav-section">
          {/* <span className="material-icons" role="button">notifications_none</span> */}
          <button
            onClick={() => {
              swal("Are You Sure?", "You Will Exit the App", "warning", {
                buttons: {
                  cancel: {
                    text: "Back",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                  confirm: {
                    text: "Logout",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                },
              }).then((value) => {
                if (value) {
                  this.props.logout();
                }
              });
            }}
          >
            <span className="material-icons ms-3" role="button">
              login
            </span>
          </button>
        </div>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    // enterPage : () => dispatch(enterPage())
  };
};
export default connect(null, mapDispatchToProps)(Header);
