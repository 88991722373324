import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/index.css";
// import "./assets/js/bootstrap.bundle.min.js";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { persistor, store } from "./store/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Switch>
            <App />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
