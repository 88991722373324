import {
  POST_MEMBER_FAILED,
  POST_MEMBER_REQUEST,
  POST_MEMBER_SUCCESS,
  GET_MEMBER_FAILED,
  GET_MEMBER_REQUEST,
  GET_MEMBER_SUCCESS,
} from "./type";

const initialState = {
  isLoading: false,
  data: null,
  error: "",
  postResponse: null,
};
export const member = (state = initialState, action) => {
  switch (action.type) {
    case POST_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postResponse: action.payload,
        error: "",
      };
    case POST_MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_MEMBER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_MEMBER_SUCCESS:
      return {
        isLoading: false,
        data: action.payload,
        error: "",
      };
    case GET_MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MEMBER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
