import axios from "../config";
import {
  GET_ORDER_FAILED,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  PUT_STATUS_ORDER_FAILED,
  PUT_STATUS_ORDER_REQUEST,
  PUT_STATUS_ORDER_SUCCESS,
  PUT_STATUS_ALL_ORDER_REQUEST,
  PUT_STATUS_ALL_ORDER_FAILED,
  PUT_STATUS_ALL_ORDER_SUCCESS,
  PUT_PACKAGING_FAILED,
  PUT_PACKAGING_REQUEST,
  PUT_PACKAGING_SUCCESS,
  PUT_STATUS_PACKAGING_FAILED,
  PUT_STATUS_PACKAGING_REQUEST,
  PUT_STATUS_PACKAGING_SUCCESS,
  PUT_PICKUP_FAILED,
  PUT_PICKUP_REQUEST,
  PUT_PICKUP_SUCCESS,
  CHECK_NEW_DATA,
} from "./type";
// import data from "../../assets/data/dummyData.json";

export const getOrder = ({
  token,
  limit = 10,
  page = 1,
  status = "incoming",
  search = "",
  startDate,
  endDate,
  courier = "",
  filter = false,
}) => {
  return (dispatch) => {
    dispatch(orderRequest());
    axios
      .get(
        `order?per_page=${limit}&page=${page}&status=${status}&search=${search}${
          filter
            ? `&start_date=${startDate}&end_date=${endDate}${
                courier !== "" ? `&courier=${courier}` : ``
              }`
            : ``
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            // if(page===1){
            //   dispatch(orderSuccess(data.Incoming));
            // }else{
            //   dispatch(orderSuccess(data.Incoming2));
            // }
            dispatch(orderSuccess(response.data));
          }
        }
      })
      .catch((error) => {
        dispatch(orderFailed(error));
      });
  };
};

export const orderSuccess = (dataUser) => {
  return {
    type: GET_ORDER_SUCCESS,
    payload: dataUser,
  };
};
export const orderRequest = () => {
  return { type: GET_ORDER_REQUEST };
};
export const orderFailed = (error) => {
  return {
    type: GET_ORDER_FAILED,
    payload: error,
  };
};

export const putStatusOrder = ({ id, token }) => {
  return (dispatch) => {
    dispatch(putStatusorderRequest());
    // setTimeout(function () {
    //   dispatch(putStatusorderSuccess("seuke"));
    // }, 3000);
    axios
      .put(
        `order/${id}`,
        {
          status: "process",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            // dispatch(orderSuccess(data.Incoming));
            dispatch(putStatusorderSuccess(response.data));
          }
        }
      })
      .catch((error) => {
        dispatch(putStatusorderFailed(error));
      });
  };
};

export const putStatusorderSuccess = (dataUser) => {
  return {
    type: PUT_STATUS_ORDER_SUCCESS,
    payload: dataUser,
  };
};
export const putStatusorderRequest = () => {
  return { type: PUT_STATUS_ORDER_REQUEST };
};
export const putStatusorderFailed = (error) => {
  return {
    type: PUT_STATUS_ORDER_FAILED,
    payload: error,
  };
};
export const putStatusAllOrder = ({ ids, token }) => {
  var updateData = {
    id: ids,
  };
  return (dispatch) => {
    dispatch(putStatusAllorderRequest());
    // setTimeout(function () {
    //   dispatch(putStatusorderSuccess("seuke"));
    // }, 3000);

    axios
      .post("order/multi_process", updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            // dispatch(orderSuccess(data.Incoming));
            dispatch(putStatusAllorderSuccess(response.data));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(putStatusAllorderFailed(error.response.data));
        } else {
          dispatch(putStatusAllorderFailed(error));
        }
      });
  };
};

export const putStatusAllorderSuccess = (dataUser) => {
  return {
    type: PUT_STATUS_ALL_ORDER_SUCCESS,
    payload: dataUser,
  };
};
export const putStatusAllorderRequest = () => {
  return { type: PUT_STATUS_ALL_ORDER_REQUEST };
};
export const putStatusAllorderFailed = (error) => {
  return {
    type: PUT_STATUS_ALL_ORDER_FAILED,
    payload: error,
  };
};

export const putPackaging = ({ data, token, id }) => {
  var updateData = {
    status: "update",
    items: data,
  };
  return (dispatch) => {
    dispatch(putPackagingRequest());
    axios
      .put(`order/${id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((value) => {
        if (value.status === 200) {
          if (value.data.status) {
            // dispatch(orderSuccess(data.Incoming));
            dispatch(putPackagingSuccess(value.data));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(putPackagingFailed(error.response.data));
        } else {
          dispatch(putPackagingFailed(error));
        }
      });
  };
};

export const putPackagingSuccess = (dataUser) => {
  return {
    type: PUT_PACKAGING_SUCCESS,
    payload: dataUser,
  };
};
export const putPackagingRequest = () => {
  return { type: PUT_PACKAGING_REQUEST };
};
export const putPackagingFailed = (error) => {
  return {
    type: PUT_PACKAGING_FAILED,
    payload: error,
  };
};

export const putStatusPackaging = ({ token, id }) => {
  var updateData = {
    status: "packed",
  };
  return (dispatch) => {
    dispatch(putStatusPackagingRequest());
    axios
      .put(`order/${id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((value) => {
        if (value.status === 200) {
          if (value.data.status) {
            // dispatch(orderSuccess(data.Incoming));
            dispatch(putStatusPackagingSuccess(value.data));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(
            putStatusPackagingFailed({
              response: {
                data: {
                  message: "Failed on update status Packed",
                },
              },
            })
          );
          // dispatch(putStatusPackagingFailed(error.response.data));
        } else {
          dispatch(
            putStatusPackagingFailed({
              response: {
                data: {
                  message: "Something Wrong, check internet connection",
                },
              },
            })
          );
          // dispatch(putStatusPackagingFailed(error));
        }
      });
  };
};

export const putStatusPackagingSuccess = (dataUser) => {
  return {
    type: PUT_STATUS_PACKAGING_SUCCESS,
    payload: dataUser,
  };
};
export const putStatusPackagingRequest = () => {
  return { type: PUT_STATUS_PACKAGING_REQUEST };
};
export const putStatusPackagingFailed = (error) => {
  return {
    type: PUT_STATUS_PACKAGING_FAILED,
    payload: error,
  };
};

export const putPickup = ({ data, token, id }) => {
  const formData = new FormData();
  // var updateData = {
  //   status: "delivered",
  //   courier: data,
  // };
  formData.append("name", data.name);
  formData.append("driver", data.driver);
  formData.append("vehicle", data.vehicle);
  formData.append("police_no", data.police_no);
  formData.append("driver_photo", data.driver_photo);
  return (dispatch) => {
    dispatch(putPickupRequest());
    axios
      .put(`order/delivery/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((value) => {
        if (value.status === 200) {
          if (value.data.status) {
            // dispatch(orderSuccess(data.Incoming));
            dispatch(putPickupSuccess(value.data));
          }
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(putPickupFailed(error.response.data));
        } else {
          dispatch(putPickupFailed(error));
        }
      });
  };
};

export const putPickupSuccess = (dataUser) => {
  return {
    type: PUT_PICKUP_SUCCESS,
    payload: dataUser,
  };
};
export const putPickupRequest = () => {
  return { type: PUT_PICKUP_REQUEST };
};
export const putPickupFailed = (error) => {
  return {
    type: PUT_PICKUP_FAILED,
    payload: error,
  };
};

export const checkNewData = ({ token, url }) => {
  return (dispatch) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            dispatch(checkNewDataSucces(response.data));
          }
        }
      });
  };
};

export const checkNewDataSucces = (data) => {
  return {
    type: CHECK_NEW_DATA,
    payload: data,
  };
};
